import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps';

import './About.less';

import React, {Component} from 'react';

const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap defaultZoom={8} defaultCenter={{lat: -34.397, lng: 150.644}}>
            {props.isMarkerShown && <Marker position={{lat: -34.397, lng: 150.644}} />}
        </GoogleMap>
    ))
);

export default class About extends Component {
    render() {
        return (
            <div>
                <MyMapComponent
                    isMarkerShown
                    googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBhzlST8S63iZiYfRDjSbYTXw8CjlMu_Os&v=3.exp&libraries=geometry,drawing,places'
                    loadingElement={<div style={{height: `100%`}} />}
                    containerElement={<div style={{height: `400px`}} />}
                    mapElement={<div style={{height: `100%`}} />}
                />
            </div>
        );
    }
}
