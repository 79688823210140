import React, {Component} from 'react';
import moment from 'moment';
import {Button, Card, Layout, Row, Col, Space, Alert} from 'antd';
import {UsergroupAddOutlined} from '@ant-design/icons';
import Axios from 'axios';
import Declensions from '../../components/Declensions/Declensions';
import DeleteEventModal from './DeleteEventModal';
import CreateEventModal from './CreateEventModal';
import './EventNotificaitons.less';

const {Content} = Layout;

export default class EventNotifications extends Component {
    state = {
        loading: true,
        categories: [
            {value: 'SIGNUP_REMINDER', title: 'Muna að skrá sig', description: 'Fer á alla leikmenn í aðal hópnum (ekki varamenn).'},
            {value: 'SPOT_IN_DANGER', title: 'Sæti í hættu', description: 'Sendist á leikmenn sem hafa ekki enn svarað stuttu áður en þeir eiga á hættu að missa sætið sitt (gul viðvörun).'},
            {value: 'SHOW_UP_REMINDER', title: 'Mæting eftir..', description: 'Áminning stuttu fyrir viðburðinn.'},
            {value: 'NOTIFY_SUBSTITUTES', title: 'Varamenn', description: 'Sendist á varamenn EF það eru laus sæti þegar gul viðvörun skellur á.'},
        ],
        weekdays: {
            MONDAY: 'mánudögum',
            TUESDAY: 'þriðjudögum',
            WEDNESDAY: 'miðvikudögum',
            THURSDAY: 'fimmtudögum',
            FRIDAY: 'föstudögum',
            SATURDAY: 'laugardögum',
            SUNDAY: 'sunnudögum',
        },
    };

    componentDidMount() {
        const p1 = Axios.get('/api/notification/?event__uuid=' + window.uuid);
        const p2 = Axios.get('/api/event/' + window.uuid);

        Promise.all([p1, p2]).then((rsp) => {
            this.setState({
                notifications: rsp[0].data.results,
                event: rsp[1].data,
                team: rsp[1].data.team,
                loading: false,
            });
        });
    }

    update_notification(notification) {
        const i = this.state.notifications.findIndex((n) => n.id === notification.id);

        let clone = JSON.parse(JSON.stringify(this.state.notifications));
        clone[i] = notification;

        this.setState({
            notifications: clone,
        });
    }

    add_remove_notification(notification, action) {
        if (action === 'remove') {
            this.setState({notifications: this.state.notifications.filter((n) => n.id !== notification.id)});
        } else if (action === 'add') {
            this.setState({notifications: [...this.state.notifications, notification]});
        }
    }

    replace_notification_variables(notification) {
        let text = notification.content;
        const team_name = /\[team_name\]/g;
        text = text.replace(team_name, this.state.team?.name);

        const session_title = /\[session_title\]/g;
        text = text.replace(session_title, notification.event?.title);

        const session_time = /\[session_time\]/g;
        const time_text = notification.time_of_day
            .split(':')
            .filter((val, key) => key < 2)
            .join(':');
        text = text.replace(session_time, time_text);

        const session_link = /\[session_link\]/g;
        text = text.replace(session_link, 'https://vantaribolta.is/aminning/1');

        const session_day = /\[session_day\]/g;
        text = text.replace(session_day, 'í dag');

        return text;

        // if '[session_time]' in content:
        //     content = content.replace(
        //         '[session_time]', session_date.format('HH:mm'))
        // if '[session_day]' in content:
        //     content = content.replace('[session_day]', today_tomorrow_string)
        // if '[session_link]' in content:
        //     content = content.replace('[session_link]', session_link)
    }

    render() {
        return (
            <>
                <Layout>
                    <Content>
                        <Row gutter={[10, 10]} style={{margin: 10}}>
                            <Col xs={24} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Card
                                    loading={this.state.loading}
                                    title='Áminningar'
                                    extra={
                                        <Button
                                            onClick={() => {
                                                this.setState({showCreateModal: true, edit_notification: null});
                                            }}
                                            className='btn-success'
                                            type='primary'
                                        >
                                            + Bæta við
                                        </Button>
                                    }
                                >
                                    <>
                                        <Alert
                                            message={
                                                <>
                                                    Áminningar fyrir atburð: <strong>{this.state.event?.title}</strong>
                                                </>
                                            }
                                            description={
                                                <>
                                                    <strong>{this.state.event?.title}</strong> er {this.state.event?.frequency_count === 1 && <span>vikulega </span>}
                                                    <span>á </span>
                                                    <strong>
                                                        <Declensions style={{display: 'inline-block'}} declenasion='thagufall' plural={true}>
                                                            {this.state.event?.day_of_week}
                                                        </Declensions>{' '}
                                                    </strong>
                                                    klukkan
                                                    <strong>
                                                        {this.state.event?.time_of_day &&
                                                            this.state.event?.time_of_day
                                                                .split(':')
                                                                .filter((val, key) => key < 2)
                                                                .join(':')}
                                                    </strong>
                                                </>
                                            }
                                        ></Alert>
                                    </>
                                    <br />

                                    {!!this.state.notifications && !!this.state.notifications.length ? (
                                        <div>
                                            <Space direction='vertical' style={{display: 'flex'}}>
                                                {this.state.notifications.map((notification, key) => {
                                                    const now_iso_day = moment().isoWeekday();

                                                    const next_sess_diff = this.state.event?.iso_day - now_iso_day;
                                                    let next_session_date = moment(this.state.event.time_of_day, 'HH:mm');
                                                    if (next_sess_diff > 0) {
                                                        next_session_date.add(next_sess_diff, 'days');
                                                    } else if (next_sess_diff < 0) {
                                                        next_session_date.subtract(Math.abs(next_sess_diff), 'days').add(7, 'days');
                                                    }

                                                    let next_notificaiton_date = moment(next_session_date).set({hour: notification.time_of_day.split(':')[0], minute: notification.time_of_day.split(':')[1]});
                                                    const event_notification_diff = this.state.event.iso_day - notification.iso_day;

                                                    if (event_notification_diff > 0) {
                                                        next_notificaiton_date.subtract(event_notification_diff, 'days');
                                                    } else if (event_notification_diff < 0) {
                                                        next_notificaiton_date.add(Math.abs(event_notification_diff), 'days').subtract(7, 'days');
                                                    }

                                                    let duration = moment.duration(next_session_date.diff(next_notificaiton_date));

                                                    const days = Math.floor(duration.asDays());
                                                    duration.subtract(moment.duration(days, 'days'));

                                                    const hours = Math.floor(duration.hours());
                                                    duration.subtract(moment.duration(hours, 'hours'));

                                                    const minutes = Math.floor(duration.minutes());
                                                    duration.subtract(moment.duration(minutes, 'minutes'));

                                                    const degi_dogum = days ? days + (days === 1 ? ' degi' : ' dögum') : ' samdægurs ';
                                                    const tima_timum = hours ? hours + (hours === 1 ? ' tíma' : ' tímum') : null;
                                                    const min_mins = minutes ? minutes + (minutes === 1 ? ' mínútu' : ' mínútum') : null;

                                                    const list = [degi_dogum, tima_timum, min_mins].filter((words) => !!words);
                                                    const final = list.filter((item) => item);

                                                    const sentence_middle = final
                                                        .map((words, i) => {
                                                            const og = final.length > 1 && i === final.length - 1 ? 'og ' : '';
                                                            if (words) {
                                                                return og + words;
                                                            }

                                                            return '';
                                                        })
                                                        .join(' ');

                                                    const desc = sentence_middle + ' fyrir viðburðinn';

                                                    const active_type = this.state.categories?.filter((obj) => obj.value === notification.category)[0];

                                                    // if (next_notificaiton_date.diff(next_session_date) > 0) next_notificaiton_date.subtract(7, 'days');

                                                    return (
                                                        <Card
                                                            key={key}
                                                            type='inner'
                                                            extra={
                                                                <Space>
                                                                    <Button
                                                                        onClick={() => {
                                                                            this.setState({showDeleteModal: true, delete_notification: notification});
                                                                        }}
                                                                        type='dashed'
                                                                        danger
                                                                    >
                                                                        Eyða
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => {
                                                                            this.setState({showCreateModal: true, edit_notification: notification});
                                                                        }}
                                                                        type='dashed'
                                                                    >
                                                                        Breyta
                                                                    </Button>
                                                                </Space>
                                                            }
                                                            title={active_type && active_type.title}
                                                        >
                                                            <div className='text-center'>
                                                                <h3>
                                                                    Sendist á {this.state.weekdays[notification.day_of_week]} klukkan{' '}
                                                                    {notification.time_of_day
                                                                        .split(':')
                                                                        .filter((val, key) => key < 2)
                                                                        .join(':')}{' '}
                                                                </h3>
                                                                <h5>({desc})</h5>
                                                            </div>

                                                            <div className='notification_preview'></div>
                                                            <div className='notification_preview_text'>
                                                                <div className='phone_info_top'>
                                                                    <div>Text Message</div>
                                                                    <div>
                                                                        Today{' '}
                                                                        {notification.time_of_day
                                                                            .split(':')
                                                                            .filter((val, key) => key < 2)
                                                                            .join(':')}{' '}
                                                                    </div>
                                                                </div>
                                                                <div className='inner_text'>{this.replace_notification_variables(notification)}</div>
                                                            </div>
                                                            <hr></hr>
                                                            <p className='text-center'>
                                                                <UsergroupAddOutlined /> {active_type && active_type.description}
                                                            </p>
                                                        </Card>
                                                    );
                                                })}
                                            </Space>
                                        </div>
                                    ) : (
                                        <>
                                            <p>Það er engin áminning til, smelltu á "Bæta við" til að búa til fyrstu áminninguna</p>
                                        </>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
                <DeleteEventModal
                    notification={this.state.delete_notification}
                    visible={this.state.showDeleteModal}
                    add_remove_notification={this.add_remove_notification.bind(this)}
                    onCancel={() => {
                        this.setState({showDeleteModal: false});
                    }}
                ></DeleteEventModal>

                {this.state.showCreateModal && (
                    <CreateEventModal
                        visible={this.state.showCreateModal}
                        categories={this.state.categories}
                        notification={this.state.edit_notification}
                        update_notification={this.update_notification.bind(this)}
                        add_remove_notification={this.add_remove_notification.bind(this)}
                        event_id={this.state.event?.id}
                        onCancel={() => {
                            this.setState({showCreateModal: false, edit_notification: null});
                        }}
                    ></CreateEventModal>
                )}
            </>
        );
    }
}
