import React from 'react';
import reactCSS from 'reactcss';
import {SketchPicker} from 'react-color';

class ColorPicker extends React.Component {
    state = {
        displayColorPicker: false,
        color: '#fff',
    };

    handleClick = () => {
        this.setState({displayColorPicker: !this.state.displayColorPicker});
    };

    handleClose = () => {
        this.setState({displayColorPicker: false});
    };

    handleChange = (color) => {
        this.setState({color: color.rgb});
        this.props.onChange(color.hex);
    };

    render() {
        const styles = reactCSS({
            default: {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.props.color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                    right: '0',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className={this.props.className}>
                <div className='color_swatch_container' style={styles.swatch} onClick={this.handleClick}>
                    <div className='color_swatch' style={styles.color} />
                </div>
                {this.state.displayColorPicker ? (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <SketchPicker disableAlpha color={this.state.color} onChange={this.handleChange} />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ColorPicker;
