import {Input} from 'antd';
import React, {Component} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import './GooglePlacesSearch.less';

// export default class LocationSearchInput extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {address: ''};
//     }

//     initalizeAPI() {
//         // Create the script tag, set the appropriate attributes
//         var script = document.createElement('script');
//         script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBhzlST8S63iZiYfRDjSbYTXw8CjlMu_Os&callback=initMap';
//         script.defer = true;
//         script.async = true;

//         // Attach your callback function to the `window` object
//         window.initMap = function () {
//             // JS API is loaded and available
//         };

//         // Append the 'script' element to 'head'
//         document.head.appendChild(script);
//     }

//     handleChange = (address) => {
//         this.setState({address});
//     };

//     handleSelect = (address) => {
//         geocodeByAddress(address)
//             .then((results) => getLatLng(results[0]))
//             .then((latLng) => console.log('Success', latLng))
//             .catch((error) => console.error('Error', error));
//     };

//     render() {
//         return (
//             <>
//                 <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
//                     {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
//                         <div>
//                             <input
//                                 {...getInputProps({
//                                     placeholder: 'Search Places ...',
//                                     className: 'location-search-input',
//                                 })}
//                             />
//                             <div className='autocomplete-dropdown-container'>
//                                 {loading && <div>Loading...</div>}
//                                 {suggestions.map((suggestion) => {
//                                     const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
//                                     // inline style for demonstration purpose
//                                     const style = suggestion.active ? {backgroundColor: '#fafafa', cursor: 'pointer'} : {backgroundColor: '#ffffff', cursor: 'pointer'};
//                                     return (
//                                         <div
//                                             {...getSuggestionItemProps(suggestion, {
//                                                 className,
//                                                 style,
//                                             })}
//                                         >
//                                             <span>{suggestion.description}</span>
//                                         </div>
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                     )}
//                 </PlacesAutocomplete>
//             </>
//         );
//     }
// }

export default class LocationSearchInput extends Component {
    state = {
        address: this.props.address,
    };

    handleInputChange = (address) => {
        this.setState({address});
        this.props.onAddressSelect(null);
    };

    onAddressSelect = (address, place_id, obj) => {
        this.setState({address});
        this.props.onAddressSelect(obj);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.address !== this.props.address) {
            this.setState({address: nextProps.address});
        }
    }

    render() {
        return (
            <PlacesAutocomplete searchOptions={{language: 'IS', componentRestrictions: {country: ['is']}}} onChange={this.handleInputChange} onSelect={this.onAddressSelect} value={this.state.address || ''}>
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <React.Fragment>
                        <Input
                            {...getInputProps({
                                id: 'address-input',
                            })}
                        />
                        <div className='autocomplete-dropdown-container'>
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion, key) => {
                                const className = suggestion.active ? 'suggestion-item ssuggestion-item--active' : 'suggestion-item';

                                const spread = {
                                    ...getSuggestionItemProps(suggestion, {
                                        className,
                                    }),
                                };

                                return (
                                    <div {...spread} key={key}>
                                        <div>{suggestion.description}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
            </PlacesAutocomplete>
        );
    }
}
