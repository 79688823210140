import React, {Component} from 'react';
import {Calendar, Avatar} from 'antd';
import moment from 'moment';
import Axios from 'axios';

export default class EventCalendar extends Component {
    state = {
        sessions: [],
        loading: true,
    };

    componentDidMount() {
        if (this.props.items) {
            this.setState({
                sessions: this.props.items,
            });
        } else {
            this.updateCalendar(moment());
        }
    }

    // componentWillReceiveProps(new_props) {
    //     if (this.props.items) {
    //         this.setState({
    //             sessions: this.props.items,
    //         });
    //     } else {
    //         this.updateCalendar(moment());
    //     }
    // }

    updateCalendar(new_moment) {
        const m = new_moment ? new_moment : moment();
        if (this.props.url) {
            const start_date = moment(m).startOf('month').startOf('isoWeek');
            const end_date = moment(m).endOf('month').endOf('isoWeek').add(1, 'week');

            Axios.get(this.props.url + '&date__gte=' + start_date.toISOString() + '&date__lte=' + end_date.toISOString()).then((rsp) => {
                this.setState({
                    loading: false,
                    sessions: rsp.data.results,
                    calendar_selected_date: moment(m),
                });
                this.props.onLoaded && this.props.onLoaded();
            });
        } else {
            this.setState({
                loading: false,
                sessions: [],
                calendar_selected_date: moment(m),
            });
            this.props.onLoaded && this.props.onLoaded();
        }
    }

    onCalendarChange(new_moment) {
        if (!new_moment.isSame(this.state.calendar_selected_date, 'month')) {
            this.updateCalendar(new_moment);
        }
    }

    render() {
        const sessions = this.state.sessions || [];
        return (
            <>
                <Calendar
                    className={this.props.className}
                    loading={this.state.loading}
                    locale={{lang: {locale: 'is_IS', month: 'Mánuður', year: 'Ár'}}}
                    onChange={(_moment) => {
                        this.onCalendarChange(_moment);
                    }}
                    onSelect={(moment_date) => {
                        if (this.props.onSelect) {
                            return this.props.onSelect(sessions, moment_date);
                        }

                        const found = sessions.filter((session) => moment(session.date).isSame(moment_date, 'day'));
                        if (found.length === 1) window.top.location.href = '/timi/' + found[0].uuid + '/';
                    }}
                    dateCellRender={(moment_date) => {
                        if (this.props.dateCellRender) {
                            return this.props.dateCellRender(sessions, moment_date);
                        }

                        return (
                            <>
                                {sessions
                                    ?.filter((session) => moment(session.date).isSame(moment_date, 'day'))
                                    .map((sess) => {
                                        return (
                                            <a key={sess.id} href={'/timi/' + sess.uuid + '/'}>
                                                <Avatar size='small' src={sess.event?.category?.icon}></Avatar>
                                            </a>
                                        );
                                    })}
                            </>
                        );
                    }}
                ></Calendar>
            </>
        );
    }
}
