import axios from 'axios';
import {GET_USER} from './types';

// GET USER
export const getUser = () => (dispatch) => {
    if (window.me) {
        axios
            .get('/api/user/' + window.me + '/')
            .then((rsp) => {
                dispatch({
                    type: GET_USER,
                    payload: rsp.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_USER,
                    payload: null,
                });
            });
    } else {
        dispatch({
            type: GET_USER,
            payload: null,
        });
    }
};
