import React, {Component} from 'react';
import {Space, Button, Modal, Select} from 'antd';
import TeamShirt from '../../../components/TeamShirt/TeamShirt';

export default class EditPlayerModal extends Component {
    componentDidMount() {
        this.setState({
            selected_list: this.props.defaultValue,
        });
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                title={this.props.title}
                footer={
                    <div className='text-center'>
                        <Space direction='vertical' style={{width: '100%'}}>
                            <Select
                                defaultValue={this.props.defaultValue}
                                style={{width: '100%'}}
                                onChange={(change) => {
                                    this.setState({
                                        selected_list: change,
                                    });
                                }}
                            >
                                <Select.Option value='confirmed'>Mætir</Select.Option>
                                <Select.Option value='cant_make_it'>Mætir ekki</Select.Option>
                                <Select.Option value='unsure'>Á eftir að svara</Select.Option>

                                {!!!this.props.is_og_player && <Select.Option value='waiting_list'>Færa á biðlista</Select.Option>}
                            </Select>
                            <Button
                                onClick={() => {
                                    this.props.onClick(this.state.selected_list);
                                }}
                                size='large'
                                block
                                type='primary'
                                className='btn-success'
                            >
                                Staðfesta
                            </Button>
                        </Space>
                    </div>
                }
            >
                <div className='text-center'>
                    <h2>{this.props.player?.user?.signature_name || this.props.player?.unconfirmed_name}</h2>
                    <TeamShirt
                        style={{width: 200}}
                        className='shirt'
                        kit_color={this.props.is_og_player ? this.props.team?.main_kit_color : this.props.team?.away_kit_color}
                        kit_secondary_color={this.props.is_og_player ? this.props.team?.main_kit_secondary_color : this.props.team?.away_kit_secondary_color}
                        kit_template={this.props.is_og_player ? this.props.team?.main_kit_template : this.props.team?.away_kit_template}
                    ></TeamShirt>
                </div>
            </Modal>
        );
    }
}
