import React, {Component} from 'react';
import {Button, Modal, Form, Input, Select, TimePicker} from 'antd';
import Axios from 'axios';
import moment from 'moment';

export default class CreateEventModal extends Component {
    formRef = React.createRef();

    state = {
        loading: true,
    };

    componentDidMount() {
        this.setState({
            loading: false,
        });
    }

    timePickerBlur(time) {
        this.formRef.current.setFieldsValue({
            time_of_day: time,
        });
    }

    submit(data) {
        if (data.time_of_day) data.time_of_day = data.time_of_day.format('HH:mm');
        if (!!!this.props.notification) {
            data.event = this.props.event_id;
            Axios.post('/api/notification/create/', {
                ...data,
            }).then((rsp) => {
                this.props.add_remove_notification(rsp.data, 'add');
                this.props.onCancel();
            });
        } else {
            Axios.patch('/api/notification/update/' + this.props.notification.id + '/', {
                ...data,
            }).then((rsp) => {
                this.props.update_notification(rsp.data);
                this.props.onCancel();
            });
        }
    }

    render() {
        return this.props.categories ? (
            <Modal
                footer={null}
                visible={this.props.visible}
                title={this.state.is_update ? 'Uppfæra áminningu' : 'Búa til nýja áminningu'}
                onCancel={() => {
                    this.formRef.current.resetFields();
                    this.props.onCancel();
                }}
            >
                <Form ref={this.formRef} layout='vertical' name='basic' onFinish={this.submit.bind(this)}>
                    <Form.Item
                        rules={[
                            {required: true, message: 'Þú verður að fylla þennan reit út!'},
                            {max: 140, message: 'Þessi reitur má ekki innihalda fleiri en 140 stafi.'},
                        ]}
                        name='content'
                        label='Texti'
                        initialValue={this.props.notification?.content || ''}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item rules={[{required: true, message: 'Þú verður að fylla þennan reit út!'}]} name='category' label='Tegund áminningar' initialValue={this.props.notification?.category || ''}>
                        <Select style={{width: '100%'}}>
                            {this.props.categories.map((not, key) => {
                                return (
                                    <Select.Option key={key} value={not.value}>
                                        {not.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item rules={[{required: true, message: 'Þú verður að fylla þennan reit út!'}]} name='day_of_week' label='Dagur' initialValue={this.props.notification?.day_of_week || null}>
                        <Select style={{width: '100%'}}>
                            <Select.Option value='MONDAY'>Mánudagur</Select.Option>
                            <Select.Option value='TUESDAY'>Þriðjudagur</Select.Option>
                            <Select.Option value='WEDNESDAY'>Miðvikudagur</Select.Option>
                            <Select.Option value='THURSDAY'>Fimmtudagur</Select.Option>
                            <Select.Option value='FRIDAY'>Föstudagur</Select.Option>
                            <Select.Option value='SATURDAY'>Laugardagur</Select.Option>
                            <Select.Option value='SUNDAY'>Sunnudagur</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Tími dags'
                        name='time_of_day'
                        rules={[{required: true, message: 'Þú verður að velja tíma dags.'}]}
                        initialValue={this.props.notification && moment(this.props.notification?.time_of_day, 'HH:mm')}
                    >
                        <TimePicker allowClear={false} showNow={false} onSelect={this.timePickerBlur.bind(this)} format='HH:mm' placeholder='Veltu tíma' />
                    </Form.Item>
                    <Form.Item>
                        <Button block className='btn-success' htmlType='submit' size='large'>
                            {this.state.is_update ? 'Uppfæra' : 'Vista'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        ) : (
            <div></div>
        );
    }
}
