import React, {Component} from 'react';
import {Space, Button, Modal} from 'antd';
//REDUX
import {connect} from 'react-redux';
import {getUser} from '../../../actions/user';

export class ApplyToJoinList extends Component {
    componentDidMount() {
        this.props.getUser();
    }

    render() {
        return (
            <Modal
                footer={null}
                visible={this.props.visible}
                title='Sækja um að gerast leikmaður'
                onCancel={() => {
                    this.props.close();
                }}
            >
                <Space direction='vertical'>
                    {window.me ? (
                        <>
                            {this.props.is_substitute && <p>Þú ert þegar á varamannabekknum, vilt þú sækja um í aðal liðinu?</p>}
                            <Button
                                onClick={() => {
                                    this.props.addPlayerToList(this.props.player, this.props.list_name, true);
                                }}
                                className='btn-success'
                                size='large'
                                type='primary'
                            >
                                Sækja um
                            </Button>
                        </>
                    ) : (
                        <>
                            <h3>Þú verður að vera innskráð/ur til að sækja um!</h3>
                            <Space>
                                <Button size='large' block type='primary' className='btn-success'>
                                    <a href='/nyskra/'>Búa til aðgang</a>
                                </Button>

                                <Button size='large' block type='dashed'>
                                    <a href='/innskra/'>Innskrá</a>
                                </Button>
                            </Space>
                        </>
                    )}
                </Space>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, {getUser})(ApplyToJoinList);
