import React, {Component} from 'react';
import {Alert, Layout, Card, Col, Row, Space, Menu, Button, message, Dropdown, List, Avatar, Timeline, Modal, Form, Input, Tabs, Table} from 'antd';
import TeamShirt from '../../components/TeamShirt/TeamShirt';
import {ClockCircleFilled, CloseCircleFilled, DeleteOutlined, MedicineBoxOutlined, HomeOutlined, EditOutlined, PhoneOutlined, CreditCardOutlined, DownOutlined, MailOutlined, SettingFilled} from '@ant-design/icons';

//REDUX
import {connect} from 'react-redux';
import {getUser} from '../../actions/user';

import './Session.less';

// Libraries
import {FacebookProvider, SendToMessenger} from 'react-facebook';
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/is';

// Modals
import EditPlayerModal from './modals/EditPlayerModal';
import DivideIntoTeamsModal from './modals/DivideIntoTeamsModal';
import SendSessionReportModal from './modals/SendSessionReportModal';
import EditSessionModal from './modals/EditSessionModal';

// Components
import ChatBox from '../../components/ChatBox/ChatBox';

const {Content} = Layout;
const {TabPane} = Tabs;

export class Session extends Component {
    friendFormRef = React.createRef();

    state = {
        loading: true,
        session: {},
        playersRefresh: false,
        waiting_list_modal: false,
        fill_slot_modal: false,
        emergency: false,
        in_need: false,
    };

    componentDidMount() {
        this.props.getUser();
        Axios.get('/api/session/' + window.uuid + '/')
            .then((rsp) => {
                this.setState({
                    loading: false,
                    session: rsp.data,
                    is_in_team: rsp.data.event?.team?.players.some((player) => {
                        return player.user?.id === window.me;
                    }),
                    is_substitute: rsp.data.event?.team?.substitutes.some((player) => {
                        return player.user?.id === window.me;
                    }),
                });

                Axios.get('/api/log/?ordering=-created&category=Session&instance_id=' + rsp.data.id).then((rsp) => {
                    this.setState({
                        logs: rsp.data.results,
                    });
                });
            })
            .catch((err) => {
                message.error('Úps, Það finnst enginn tími með þessu númeri :(');
            });

        Axios.get('/api/message/?session__uuid=' + window.uuid).then((rsp) => {
            this.setState({
                sent_notifications: rsp.data.results,
            });
        });
    }

    isCaptain = (player) => {
        return this.state.session.event.team?.captains.filter((pl) => pl.id === player.id).length;
    };

    filter_og_players_in_list = (list) => {
        list = list || [];
        const players = this.state.session.event.team.players;

        return list.filter((player) => players.some((pl) => player.id === pl.id));
    };

    is_og_player = (player) => {
        if (!player) return false;
        return this.state.session.event?.team?.players.some((pl) => pl.id === player.id);
    };

    is_substitute_player = (player) => {
        if (!player) return false;
        return this.state.session.event?.team?.substitutes.some((pl) => pl.id === player.id);
    };

    in_need_empty_seats = () => {
        const ideal_player_count = this.state.session.event?.ideal_player_count || 0;
        const confirmed_count = this.state.session.confirmed?.length || 0;

        const players_needed = ideal_player_count - confirmed_count;

        return players_needed;
    };

    not_in_need_empty_seats = () => {
        const player_count = this.state.session.event?.team?.players?.length;
        const og_cant_make_it_count = this.filter_og_players_in_list(this.state.session.cant_make_it).length;
        const ideal_player_count = this.state.session.event?.ideal_player_count || 0;
        const confirmed_count = this.state.session.confirmed?.length || 0;
        const og_confirmed_count = this.filter_og_players_in_list(this.state.session.confirmed).length;
        const players_needed = ideal_player_count - confirmed_count;
        const yet_to_answer_count = player_count - og_cant_make_it_count - og_confirmed_count;

        return players_needed - yet_to_answer_count;
    };

    empty_seats = () => {
        if (!this.state.session.confirmed) return 0;

        const ideal_player_count = this.state.session.event?.ideal_player_count || 0;
        const confirmed_count = this.state.session.confirmed?.length || 0;
        if (ideal_player_count <= confirmed_count) {
            return 0;
        }

        const empty_seats = this.state.in_need ? this.in_need_empty_seats() : this.not_in_need_empty_seats();
        return empty_seats > 0 ? empty_seats : 0;
    };

    session_is_full = () => {
        return this.state.session.confirmed?.length >= this.state.session.event?.ideal_player_count;
    };

    player_answer_count = () => {
        if (!this.state.session.confirmed) return 0;

        return [...this.state.session.confirmed, ...this.state.session.cant_make_it].filter((player) => {
            return this.state.session.event?.team?.players.map((team_player) => {
                return team_player.id === player.id;
            });
        }).length;
    };

    remove_player_from_list = (player, list_name) => {
        let session = {...this.state.session};
        session[list_name] = [...this.state.session[list_name].filter((pl) => player.id !== pl.id)];

        Axios.post(
            '/api/add_remove_player_to_session_list/',
            JSON.stringify({
                player: player.uuid,
                session: this.state.session.uuid,
                list_name: list_name,
                action: 'remove',
            })
        )
            .then((rsp) => {
                message.success('Tókst!');
                this.setState({
                    session,
                });

                if (list_name === 'waiting_list') {
                    this.setState({logs: [{slug: 'player_removed_from_waiting_list', player}, ...this.state.logs]});
                }
            })
            .catch((err) => {
                window.test = err;
                if (err.response?.data?.message) {
                    message.error(err.response?.data?.message);
                } else {
                    message.error('Úps, eitthvað fór úrskeiðis!');
                }
            });
    };

    add_player_to_list = (player, list_name) => {
        const state_list = this.state.session[list_name] || [];
        let session = {...this.state.session};
        session[list_name] = [...state_list, player];

        Axios.post(
            '/api/add_remove_player_to_session_list/',
            JSON.stringify({
                player: player.uuid,
                session: this.state.session.uuid,
                list_name: list_name,
            })
        )
            .then((rsp) => {
                message.success('Tókst!');
                this.setState({
                    session,
                });

                Axios.get('/api/log/?ordering=-created&category=Session&instance_id=' + this.state.session.id).then((rsp) => {
                    this.setState({
                        logs: rsp.data.results,
                    });
                });

                this.remove_from_other_lists(player, list_name);
            })
            .catch((err) => {
                window.test = err;
                if (err.response?.data?.message) {
                    message.error(err.response?.data?.message);
                } else {
                    message.error('Úps, eitthvað fór úrskeiðis!');
                }
            });
    };

    remove_from_other_lists(player, list_name) {
        let remove_from_lists = [];

        if (list_name === 'confirmed') {
            remove_from_lists.push('cant_make_it');
            remove_from_lists.push('waiting_list');
        }

        if (list_name === 'cant_make_it') {
            remove_from_lists.push('confirmed');
        }

        if (list_name === 'unsure') {
            remove_from_lists.push('confirmed');
            remove_from_lists.push('cant_make_it');
        }

        if (list_name === 'waiting_list') {
            remove_from_lists.push('confirmed');
            remove_from_lists.push('cant_make_it');
        }

        remove_from_lists.map((rival_list_name) => {
            let session = {...this.state.session};
            session[rival_list_name] = [...this.state.session[rival_list_name].filter((list_player) => player.id !== list_player.id)];

            this.setState({
                session,
            });

            return '';
        });
    }

    submitFriend = (data) => {
        this.setState({new_friend_loading: true});

        const email = data.unconfirmed_email || '';
        let phone = data.unconfirmed_phone || '';
        const name = data.unconfirmed_name;

        if (phone) phone = encodeURIComponent('+354' + phone);

        Axios.get('/api/get_or_create_player/?email=' + email + '&phone=' + phone + '&name=' + name)
            .then((rsp) => {
                this.add_player_to_list(rsp.data, this.state.add_friend_to_list_modal);
                this.setState({new_friend_loading: false});

                this.friendFormRef.current.setFieldsValue({
                    unconfirmed_name: null,
                    unconfirmed_email: null,
                    unconfirmed_phone: null,
                });
            })
            .catch((err) => {
                this.setState({new_friend_loading: false});
                if (err.response?.status === 403) {
                    message.error('Þú hefur ekki leyfi til að framkvæma þessa aðgerð!');
                } else {
                    message.error('Úps eitthvað fór úrskeiðis, ekki tókst að bæta leikmanni við :(');
                }
            });
    };

    render() {
        const location = this.state.session?.location || this.state.session?.event?.location;
        const categoryIcon = this.state.session?.event?.category?.icon;

        return (
            <>
                <Layout id='session'>
                    <Content>
                        <Row type='flex' gutter={[10, 10]} style={{margin: 10, justifyContent: 'center'}}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={9} xxl={7}>
                                <Space direction='vertical' style={{display: 'flex'}}>
                                    {this.state.session?.confirmed && this.state.session?.confirmed.length > 2 && (
                                        <Space direction='vertical' style={{display: 'flex'}}>
                                            <Button
                                                onClick={() => {
                                                    this.setState({divide_into_teams_modal: true});
                                                }}
                                                block
                                                type='dashed'
                                                style={{background: 'transparent', color: '#fff'}}
                                                size='large'
                                            >
                                                Skipta í lið
                                            </Button>
                                        </Space>
                                    )}
                                    <Card
                                        title={
                                            <>
                                                <a style={{color: 'rgba(0, 0, 0, 0.85)'}} href={'/lid/' + this.state.session?.event?.team?.uuid + '/'}>
                                                    <Button size='small'>Skoða lið</Button> {this.state.session?.event?.team?.name || ''}
                                                </a>
                                            </>
                                        }
                                        extra={
                                            this.state.is_in_team ? (
                                                <Dropdown
                                                    overlay={
                                                        <Menu onClick={false}>
                                                            {!this.state.session.is_cancelled && (
                                                                <Menu.Item
                                                                    key='1'
                                                                    icon={<CreditCardOutlined />}
                                                                    onClick={() => {
                                                                        this.setState({edit_session_modal: this.state.session});
                                                                    }}
                                                                >
                                                                    Breyta viðburði
                                                                </Menu.Item>
                                                            )}
                                                            {!this.state.session.is_cancelled && (
                                                                <Menu.Item
                                                                    onClick={() => {
                                                                        this.setState({send_report_modal: this.state.session});
                                                                    }}
                                                                >
                                                                    <MailOutlined /> Senda liðaskýrslu (t.d vegna Covid)
                                                                </Menu.Item>
                                                            )}
                                                            {!this.state.session.is_cancelled && (
                                                                <Menu.Item
                                                                    danger
                                                                    onClick={() => {
                                                                        Axios.patch(`/api/session/${window.uuid}/update/`, {is_cancelled: true}).then((rsp) => {
                                                                            message.success('Tókst');
                                                                            this.setState({session: {...this.state.session, is_cancelled: true}});
                                                                        });
                                                                    }}
                                                                >
                                                                    <DeleteOutlined /> Fella viðburð niður
                                                                </Menu.Item>
                                                            )}
                                                            {this.state.session.is_cancelled && (
                                                                <Menu.Item
                                                                    onClick={() => {
                                                                        Axios.patch(`/api/session/${window.uuid}/update/`, {is_cancelled: false}).then((rsp) => {
                                                                            message.success('Tókst');
                                                                            this.setState({session: {...this.state.session, is_cancelled: false}});
                                                                        });
                                                                    }}
                                                                >
                                                                    <MedicineBoxOutlined /> Endurvekja viðburð
                                                                </Menu.Item>
                                                            )}
                                                        </Menu>
                                                    }
                                                >
                                                    <Button>
                                                        <SettingFilled /> Aðgerðir <DownOutlined />
                                                    </Button>
                                                </Dropdown>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        loading={this.state.loading}
                                        type='inner'
                                    >
                                        <div id='field'>
                                            {this.state.session.is_cancelled ? (
                                                <div className='text-center'>
                                                    <h1>Viðburður felldur niður</h1>
                                                    <p>Það er því miður búið að fella þennan viðburð niður.</p>
                                                </div>
                                            ) : (
                                                <>
                                                    {this.state.session.date && (
                                                        <div className='text-center' style={{fontSize: 17, borderBottom: '1px solid rgba(0,0,0,0.1)', paddingBottom: 10, marginBottom: 10}}>
                                                            <span style={{marginRight: 10}}>
                                                                Staðfestir: <strong className='successColor'>{this.state.session.confirmed.length}</strong>
                                                            </span>
                                                            {!this.session_is_full() ? (
                                                                <span>
                                                                    {' '}
                                                                    Laus pláss: <strong className='warningColor'> {this.state.session.event.ideal_player_count - this.state.session.confirmed.length}</strong>
                                                                </span>
                                                            ) : (
                                                                <strong className='successColor'> Fullbókað!</strong>
                                                            )}
                                                        </div>
                                                    )}
                                                    <div className='team'>
                                                        {this.state.session.confirmed?.map((player, i) => {
                                                            const is_og = this.is_og_player(player);

                                                            return (
                                                                <div key={i} className='player'>
                                                                    <div style={{display: 'inline-block'}}>
                                                                        <TeamShirt
                                                                            className='shirt'
                                                                            kit_color={is_og ? this.state.session?.event?.team?.main_kit_color : this.state.session?.event?.team?.away_kit_color}
                                                                            kit_secondary_color={
                                                                                is_og ? this.state.session?.event?.team?.main_kit_secondary_color : this.state.session?.event?.team?.away_kit_secondary_color
                                                                            }
                                                                            kit_template={is_og ? this.state.session?.event?.team?.main_kit_template : this.state.session?.event?.team?.away_kit_template}
                                                                        ></TeamShirt>
                                                                    </div>
                                                                    <div className='playerNameContainer'>
                                                                        <div className='playerNameAndStatus'>
                                                                            {(this.state.is_in_team || window.me === player.user?.id) && (
                                                                                <Button
                                                                                    type='link'
                                                                                    onClick={() => {
                                                                                        this.setState({edit_player_modal: true, edit_player: player});
                                                                                    }}
                                                                                    style={{position: 'absolute', right: 0, top: 0, cursor: 'pointer', color: '#787878'}}
                                                                                >
                                                                                    <EditOutlined></EditOutlined>
                                                                                </Button>
                                                                            )}
                                                                            <a style={{color: 'inherit'}} href={'/leikmadur/' + player.uuid + '/'} className='playerName capitalize'>
                                                                                {player.user?.signature_name || player.unconfirmed_name}
                                                                            </a>
                                                                            <div>
                                                                                <span role='img' aria-label='Strong'>
                                                                                    💪
                                                                                </span>{' '}
                                                                                Mætir
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                        {this.state.session.event?.team?.players
                                                            ?.filter((player) => {
                                                                return ![...this.state.session.confirmed, ...this.state.session.cant_make_it]
                                                                    .map((pl) => {
                                                                        return pl.id;
                                                                    })
                                                                    .includes(player.id);
                                                            })
                                                            .sort((player, other_player) => {
                                                                if (player.user?.id === window.me) {
                                                                    return -1;
                                                                } else {
                                                                    if (this.isCaptain(player) && other_player.user?.id !== window.me) return -1;

                                                                    return 0;
                                                                }
                                                            })
                                                            .map((player, i) => {
                                                                return (
                                                                    !this.session_is_full() &&
                                                                    !this.state.emergency &&
                                                                    !this.state.in_need && (
                                                                        <div key={i} className='player'>
                                                                            {
                                                                                <div className='confirmationButtons'>
                                                                                    <Space direction='vertical'>
                                                                                        <Button className='btn-success' block onClick={this.add_player_to_list.bind(this, player, 'confirmed')} type='primary'>
                                                                                            Mætir
                                                                                        </Button>
                                                                                        <Button block onClick={this.add_player_to_list.bind(this, player, 'cant_make_it')} danger>
                                                                                            Mætir ekki
                                                                                        </Button>
                                                                                    </Space>
                                                                                </div>
                                                                            }
                                                                            <div style={{display: 'inline-block', position: 'relative'}}>
                                                                                <TeamShirt
                                                                                    style={{opacity: 0.2}}
                                                                                    className='shirt'
                                                                                    kit_color={this.state.session?.event?.team?.main_kit_color}
                                                                                    kit_secondary_color={this.state.session?.event?.team?.main_kit_secondary_color}
                                                                                    kit_template={this.state.session?.event?.team?.main_kit_template}
                                                                                ></TeamShirt>
                                                                            </div>
                                                                            <div className='playerNameContainer'>
                                                                                {/* <div className='playerStatus'>Á eftir að svara</div> */}
                                                                                <div className='playerNameAndStatus'>
                                                                                    <a style={{color: 'inherit'}} href={'/leikmadur/' + player.uuid + '/'} className='playerName capitalize'>
                                                                                        {player.user?.signature_name || player.unconfirmed_name}
                                                                                    </a>
                                                                                    <div>Á eftir að svara</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                );
                                                            })}

                                                        {this.state.session.confirmed &&
                                                            [...Array(this.empty_seats())].map((seat, i) => {
                                                                return (
                                                                    <div key={i} className='player'>
                                                                        <div className='confirmationButtons'>
                                                                            <Space direction='vertical'>
                                                                                <br></br>
                                                                                {this.state.is_in_team || this.state.is_substitute ? (
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            this.setState({fill_slot_modal: true});
                                                                                        }}
                                                                                        block
                                                                                        type='primary'
                                                                                        className='btn-success'
                                                                                    >
                                                                                        Fylla pláss
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        block
                                                                                        type='primary'
                                                                                        className='btn-success'
                                                                                        onClick={() => {
                                                                                            this.setState({waiting_list_modal: true});
                                                                                        }}
                                                                                    >
                                                                                        Sækja um
                                                                                    </Button>
                                                                                )}
                                                                            </Space>
                                                                        </div>
                                                                        <div style={{display: 'inline-block'}}>
                                                                            <TeamShirt style={{opacity: 0.5}} className='shirt' kit_template={this.state.session.event?.team?.main_kit_template}></TeamShirt>
                                                                        </div>
                                                                        <div className='playerNameContainer'>
                                                                            <div className='playerNameAndStatus'>
                                                                                <div className='playerName'>Pláss laust</div>
                                                                                <div>🪑</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </Card>

                                    {!!!this.state.session.is_cancelled && (
                                        <Card
                                            title='Biðlistinn'
                                            loading={this.state.loading}
                                            type='inner'
                                            extra={
                                                <Button
                                                    type='primary'
                                                    className='btn-success'
                                                    onClick={() => {
                                                        this.setState({waiting_list_modal: true});
                                                    }}
                                                >
                                                    Skrá á biðlista
                                                </Button>
                                            }
                                        >
                                            {!!this.state.session.waiting_list?.length && (
                                                <div id='field'>
                                                    <div className='team'>
                                                        {this.state.session.waiting_list?.map((player) => {
                                                            const is_substitute = !!this.state.session.event?.team?.substitutes.filter((substitute) => {
                                                                return substitute.id === player.id;
                                                            }).length;
                                                            return (
                                                                <div key={player.id} className='player'>
                                                                    <TeamShirt
                                                                        className='shirt'
                                                                        kit_color={is_substitute ? this.state.session.event?.team?.away_kit_color : false}
                                                                        kit_secondary_color={is_substitute ? this.state.session.event?.team?.away_kit_secondary_color : false}
                                                                        kit_template={this.state.session?.event?.team?.away_kit_template}
                                                                    ></TeamShirt>
                                                                    <div className='playerNameContainer'>
                                                                        {is_substitute && <div className='playerStatus'>Með forgang (varamaður)</div>}
                                                                        <div className='playerNameAndStatus'>
                                                                            {(this.state.is_in_team || window.me === player.user?.id) && (
                                                                                <div
                                                                                    onClick={() => this.remove_player_from_list(player, 'waiting_list')}
                                                                                    style={{position: 'absolute', right: 4, top: 0, cursor: 'pointer'}}
                                                                                >
                                                                                    <CloseCircleFilled style={{color: '#f74d4f'}} />
                                                                                </div>
                                                                            )}

                                                                            <a className='playerName capitalize' href={'/leikmadur/' + player.uuid + '/'}>
                                                                                <div className='capitalize'>{player.user?.signature_name || player.unconfirmed_name}</div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}

                                            {!this.state.session.waiting_list?.length && (
                                                <div className='text-center'>
                                                    Biðlistinn er tómur
                                                    <span role='img' aria-label='Grumpy'>
                                                        😒
                                                    </span>
                                                </div>
                                            )}
                                        </Card>
                                    )}
                                </Space>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8} xl={9} xxl={7}>
                                <Card bodyStyle={{textAlign: 'center', padding: 0}} className='location_box transparent_box' loading={this.state.loading}>
                                    <h2>{this.state.session.event?.title || ''}</h2>
                                    <div>
                                        <Avatar
                                            size={30}
                                            style={{color: '#e100fe', background: categoryIcon ? 'transparent' : '#fff'}}
                                            icon={categoryIcon ? <img alt='Flokkur' src={categoryIcon} /> : <HomeOutlined />}
                                            src={location?.main_image}
                                        >
                                            {location?.title}
                                        </Avatar>
                                    </div>

                                    <h3>{location?.title}</h3>
                                    <h4>{location?.google_place_secondary_text}</h4>
                                    <hr></hr>
                                    <h3 className='mb0'>
                                        <ClockCircleFilled />{' '}
                                        <Moment locale='is' format='LT'>
                                            {this.state.session?.date}
                                        </Moment>
                                    </h3>
                                    <h4 className='mb0 mt0 weekday'>
                                        <Moment locale='is' format='dddd'>
                                            {this.state.session?.date}
                                        </Moment>
                                        ,{' '}
                                        <Moment locale='is' format='LL'>
                                            {this.state.session?.date}
                                        </Moment>
                                    </h4>

                                    <p>{this.state.session?.event?.team?.description}</p>
                                </Card>

                                {this.state.session.uuid && (this.state.is_in_team || this.state.is_substitute) && this.state.session.event?.team?.players && (
                                    <Card
                                        style={{
                                            background: '#ffffff47',
                                            border: 'none',
                                        }}
                                        bodyStyle={{padding: 0}}
                                        title='Spjall (beta)'
                                        type='inner'
                                        loading={this.state.loading}
                                    >
                                        <ChatBox players={this.state.session.event?.team?.players} session={this.state.session}></ChatBox>
                                    </Card>
                                )}

                                <Space direction='vertical' className='text-center' style={{display: 'flex'}}>
                                    {!this.session_is_full() && (
                                        <div style={{marginTop: 20}}>
                                            <Space direction='vertical' style={{display: 'flex'}}>
                                                {this.state.in_need ? (
                                                    <Alert message='Varamenn hafa núna sama forgang og leikmenn, fyrstur kemur fyrstur fær!' type='warning' />
                                                ) : (
                                                    <div style={{color: 'white'}}>
                                                        Gul viðvörun:{' '}
                                                        <strong>
                                                            <Moment subtract={{hours: this.state.session.event?.team?.in_need_buffer}} locale='is' format='LT'>
                                                                {this.state.session?.date}
                                                            </Moment>{' '}
                                                        </strong>
                                                    </div>
                                                )}

                                                {this.state.emergency ? (
                                                    <div style={{color: 'white'}}>
                                                        <h3>Nú er neyðarástand!</h3>
                                                        <div>Leikmenn geta bætt við fólki sem er hvorki í leikmannahópnum né á varamannabekknum</div>
                                                    </div>
                                                ) : (
                                                    <div style={{color: 'white'}}>
                                                        Rauð viðvörun:{' '}
                                                        <strong>
                                                            <Moment subtract={{hours: this.state.session.event?.team?.emergency_buffer}} locale='is' format='LT'>
                                                                {this.state.session?.date}
                                                            </Moment>{' '}
                                                        </strong>
                                                    </div>
                                                )}

                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            about_emergency_modal: true,
                                                        });
                                                    }}
                                                    type='link'
                                                    block
                                                    style={{color: '#fff', textDecoration: 'underline'}}
                                                >
                                                    Hvað þýðir það?
                                                </Button>

                                                {!this.state.loading && (this.state.is_in_team || this.state.is_substitute) && !this.props.user?.fb_recipient_id && (
                                                    <>
                                                        <hr />
                                                        <h3>Fáðu tilkynningar á messenger</h3>
                                                        <div className='text-left' style={{width: 178, margin: 'auto'}}>
                                                            <FacebookProvider appId='238324500800347'>
                                                                <SendToMessenger messengerAppId='238324500800347' pageId='104971504676510' dataRef={window.me} size='xlarge' color='white' />
                                                            </FacebookProvider>
                                                        </div>
                                                        <hr />
                                                    </>
                                                )}
                                            </Space>
                                        </div>
                                    )}

                                    {/* {this.state.is_in_team && (
                                        <Card loading={this.state.loading} title='Aðgerðir' type='inner'>
                                            <Space direction='vertical' style={{display: 'flex'}}>
                                                <Button block type='primary' block size='large'>
                                                    Skipta í lið
                                                </Button>
                                                <Button block type='dashed' block size='large'>
                                                    + Bjóða fólki
                                                </Button>
                                                <br />
                                                <Button block type='primary' block danger size='large'>
                                                    Fella niður tíma
                                                </Button>
                                            </Space>
                                        </Card>
                                    )} */}

                                    <Card loading={this.state.loading && this.state.logs} className='transparent_box'>
                                        <Tabs className='sessionTabs' style={{color: '#fff'}} defaultActiveKey='timeline' centered>
                                            <TabPane tab='Aðgerðasaga' key='timeline'>
                                                {this.state.logs?.length ? (
                                                    <Timeline mode='right'>
                                                        {this.state.logs.map((log, key) => {
                                                            return (
                                                                <Timeline.Item
                                                                    key={key}
                                                                    color={log.slug === 'player_marked_confirmed' || log.slug === 'player_added_to_waiting_list' ? 'green' : 'red'}
                                                                    label={
                                                                        <>
                                                                            <div>
                                                                                <Moment locale='is' format='HH:mm - D. MMM'>
                                                                                    {log.created}
                                                                                </Moment>
                                                                            </div>
                                                                            <div style={{fontSize: '9px'}}>{log.user?.signature_name}</div>
                                                                        </>
                                                                    }
                                                                >
                                                                    <a style={{color: '#fff'}} href={'/leikmadur/' + log.player.uuid}>
                                                                        <strong className='capitalize'>{log.player.user?.signature_name || log.player.unconfirmed_name} </strong>
                                                                        <Avatar size='small' src={log.player.user?.image?.image}>
                                                                            {(log.player.user?.signature_name || log.player.unconfirmed_name || '').charAt(0).toUpperCase()}
                                                                        </Avatar>
                                                                    </a>
                                                                    {log.slug === 'player_marked_confirmed' && ' mætir!'}
                                                                    {log.slug === 'player_marked_unsure' && ' merktur sem "á eftir að svara"'}
                                                                    {log.slug === 'player_marked_cant_make_it' && ' mætir ekki.'}
                                                                    {log.slug === 'player_added_to_waiting_list' && ' bætt við biðlistann.'}
                                                                    {log.slug === 'player_removed_from_waiting_list' && ' fjarlægð/ur af biðlistanum.'}
                                                                    {log.slug === 'player_marked_no_show' && ' merktur sem skrópari.'}
                                                                    {log.slug === 'player_marked_late' && ' merktur sem "mætti seint".'}
                                                                    {(this.state.is_in_team || log.player.user?.id === window.me) && (
                                                                        <Button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    edit_player: log.player,
                                                                                    edit_player_modal: true,
                                                                                });
                                                                            }}
                                                                            size='small'
                                                                            type='link'
                                                                            style={{background: 'transparent', color: '#fff', border: 'none'}}
                                                                        >
                                                                            <EditOutlined />
                                                                        </Button>
                                                                    )}
                                                                </Timeline.Item>
                                                            );
                                                        })}
                                                    </Timeline>
                                                ) : (
                                                    <div>Sagan fyrir þennan viðburð er ansi tíðindalaus 🥱</div>
                                                )}
                                            </TabPane>

                                            <TabPane tab='Áminningar' key='notifications'>
                                                {this.state.is_in_team && (
                                                    <div className='text-center'>
                                                        <Button className='btn-success'>
                                                            <a href={'/aminningar/' + this.state.session.event?.uuid + '/'}>Stilla áminningar</a>
                                                        </Button>
                                                    </div>
                                                )}

                                                <Space style={{width: '100%'}} direction='vertical'>
                                                    {this.state.sent_notifications?.length ? (
                                                        this.state.sent_notifications.map((notification, key) => {
                                                            return (
                                                                <Card
                                                                    key={key}
                                                                    className='sms_info'
                                                                    style={{background: 'transparent', color: '#fff', width: '100%'}}
                                                                    headStyle={{textAlign: 'left', color: '#fff'}}
                                                                    title={
                                                                        <>
                                                                            <PhoneOutlined /> SMS áminning
                                                                        </>
                                                                    }
                                                                    size='small'
                                                                    extra={
                                                                        <Moment locale='is' format='HH:mm - D. MMM'>
                                                                            {notification.created}
                                                                        </Moment>
                                                                    }
                                                                >
                                                                    <div>{notification.content}</div>
                                                                    <br />
                                                                    <h3>Viðtakendur ({notification.sms_messages.length})</h3>

                                                                    <Table
                                                                        pagination={false}
                                                                        showHeader={false}
                                                                        size='small'
                                                                        dataSource={notification.sms_messages.map((sms, key) => {
                                                                            sms.key = key;
                                                                            return sms;
                                                                        })}
                                                                        locale={{
                                                                            emptyText: 'Viðtakendalisti fannst ekki',
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                title: 'Móttakandi',
                                                                                key: 'key',
                                                                                render: (res) => res.player || 'Viðtakandi fannst ekki',
                                                                            },
                                                                        ]}
                                                                    ></Table>
                                                                </Card>
                                                            );
                                                        })
                                                    ) : (
                                                        <div>Engar áminningar hafa verið sendar fyrir þennan viðburð</div>
                                                    )}
                                                </Space>
                                            </TabPane>
                                        </Tabs>
                                    </Card>
                                </Space>
                            </Col>
                        </Row>

                        <Modal
                            visible={this.state.waiting_list_modal}
                            onCancel={() => {
                                this.setState({waiting_list_modal: false});
                            }}
                            footer={
                                <Space style={{display: 'flex'}} direction='vertical'>
                                    {this.state.session.confirmed &&
                                        !this.state.is_in_team &&
                                        window.me &&
                                        (![...this.state.session.event?.team?.players, ...this.state.session?.confirmed, ...this.state.session?.waiting_list].filter((player) => player.user?.id === window.me).length ? (
                                            <Button
                                                type='primary'
                                                size='large'
                                                block
                                                onClick={() => {
                                                    Axios.get('/api/get_or_create_player/?user=' + window.me).then((rsp) => {
                                                        this.add_player_to_list(rsp.data, 'waiting_list');
                                                    });
                                                }}
                                            >
                                                Skrá mig á biðlistann
                                            </Button>
                                        ) : (
                                            <h3 className='text-center'>Þú ert komin/n biðlistann!</h3>
                                        ))}
                                    {this.state.is_in_team && (
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    this.setState({add_friend_to_list_modal: 'waiting_list', new_friend_loading: false, waiting_list_modal: false});
                                                }}
                                                type='primary'
                                                size='large'
                                                block
                                            >
                                                Skrá vin á biðlistann
                                            </Button>

                                            <h3 className='text-center mt20'>eða bæta varamanni á biðlista:</h3>
                                            <br />
                                            <List
                                                className='text-left'
                                                locale={{emptyText: 'Það eru engir varamenn til að sýna'}}
                                                itemLayout='horizontal'
                                                dataSource={this.state.session.event?.team?.substitutes}
                                                renderItem={(player) => (
                                                    <List.Item
                                                        actions={[
                                                            <Button onClick={this.add_player_to_list.bind(this, player, 'waiting_list')} className='btn-success' type='primary'>
                                                                Skrá á biðlista
                                                            </Button>,
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <TeamShirt
                                                                    style={{width: 35}}
                                                                    className='shirt'
                                                                    kit_color={this.state.session?.event?.team?.away_kit_color}
                                                                    kit_secondary_color={this.state.session?.event?.team?.away_kit_secondary_color}
                                                                    kit_template={this.state.session?.event?.team?.away_kit_template}
                                                                ></TeamShirt>
                                                            }
                                                            title={<div className='capitalize'>{player.user?.signature_name || player.unconfirmed_name}</div>}
                                                        ></List.Item.Meta>
                                                    </List.Item>
                                                )}
                                            />
                                        </div>
                                    )}

                                    {!window.me && (
                                        <div className='text-center'>
                                            <h3>Þú verður að vera innskráð/ur til að skrá þig á biðlistann!</h3>
                                            <Space>
                                                <Button size='large' block type='primary' className='btn-success'>
                                                    <a href='/nyskra/'>Búa til aðgang</a>
                                                </Button>

                                                <Button size='large' block type='dashed'>
                                                    <a href='/innskra/'>Innskrá</a>
                                                </Button>
                                            </Space>
                                        </div>
                                    )}
                                </Space>
                            }
                        ></Modal>

                        {this.state.about_emergency_modal && (
                            <Modal
                                onCancel={() => {
                                    this.setState({
                                        about_emergency_modal: false,
                                    });
                                }}
                                visible={this.state.about_emergency_modal}
                                footer={
                                    <div className='text-left'>
                                        <h2 className='mb0'>Gul viðvörun</h2>
                                        <h4>
                                            Skellur á {this.state.session?.event?.team?.in_need_buffer} tímum fyrir viðburðinn (
                                            {this.state.session && (
                                                <Moment locale='is' format='LT'>
                                                    {moment(this.state.session?.date).subtract(this.state.session?.event?.team?.in_need_buffer, 'hours')}
                                                </Moment>
                                            )}
                                            )
                                        </h4>
                                        <p>
                                            Mikilvægt er að leikmenn í aðal hópnum meldi sig áður en gul viðvörun skellur á. Á þessum tímapunkti fá <strong>varamenn</strong> sama forgang og leikmenn og geta skráð sig í
                                            laus sæti.
                                        </p>

                                        <h2 className='mb0'>Rauð viðvörun</h2>
                                        <h4>
                                            Skellur á {this.state.session?.event?.team?.emergency_buffer} tímum fyrir viðburðinn (
                                            {this.state.session && (
                                                <Moment locale='is' format='LT'>
                                                    {moment(this.state.session?.date).subtract(this.state.session?.event?.team?.emergency_buffer, 'hours')}
                                                </Moment>
                                            )}
                                            )
                                        </h4>
                                        <p>Þegar rauð viðvörun skellur á geta leikmenn bætt ókunnugum í laus sæti.</p>
                                        <p>
                                            Athugið að <strong>ókunnugir geta EKKI gert það sjálfir</strong>, þeir geta einungis bætt sér á biðlistann. Einungis leikmenn geta bætt ókunnugum af biðlistanum í laus sæti.
                                        </p>
                                    </div>
                                }
                            ></Modal>
                        )}

                        <Modal
                            visible={this.state.add_friend_to_list_modal}
                            title='Bæta vin á biðlistann'
                            onCancel={() => {
                                this.setState({add_friend_to_list_modal: false});
                            }}
                            footer={
                                <Space style={{display: 'flex'}} direction='vertical'>
                                    {this.state.session.confirmed && this.state.is_in_team && (
                                        <div className='text-center'>
                                            <p>Við mælum mjög sterklega með því að þú fyllir út símanúmer og/eða netfang leikmanns, það er þó ekki nauðsynlegt. </p>
                                            <Form ref={this.friendFormRef} onFinish={this.submitFriend} layout='vertical' name='basic'>
                                                <Form.Item
                                                    label='Nafn'
                                                    name='unconfirmed_name'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Nafn er ekki valkvætt!',
                                                        },
                                                    ]}
                                                >
                                                    <Input size='large' autoComplete='new-password' />
                                                </Form.Item>

                                                <Form.Item label='Netfang vinar' name='unconfirmed_email'>
                                                    <Input size='large' autoComplete='new-password' />
                                                </Form.Item>

                                                <Form.Item
                                                    label='Símanúmer vinar'
                                                    name='unconfirmed_phone'
                                                    rules={[
                                                        {
                                                            validator: (rule, value) => {
                                                                return !value || value.length === 7 ? Promise.resolve() : Promise.reject('Símanúmerið þarf að vera 7 stafir');
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input size='large' autoComplete='new-password' />
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button block size='large' loading={this.state.new_friend_loading} type='primary' htmlType='submit'>
                                                        Vista
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    )}
                                </Space>
                            }
                        ></Modal>

                        <Modal
                            visible={this.state.fill_slot_modal}
                            onCancel={() => {
                                this.setState({fill_slot_modal: false});
                            }}
                            title='Veldu leikmann'
                            footer={false}
                        >
                            <Space style={{display: 'flex'}} direction='vertical'>
                                <div className='text-center'>
                                    <Button
                                        onClick={() => {
                                            this.setState({add_friend_to_list_modal: 'confirmed', new_friend_loading: false, waiting_list_modal: false, fill_slot_modal: false});
                                        }}
                                    >
                                        + Bæta við ókunnugum
                                    </Button>
                                </div>

                                <h3 className='text-center'>Biðlistinn</h3>
                                <List
                                    className='text-left'
                                    locale={{emptyText: 'Biðlistinn er tómur :/'}}
                                    itemLayout='horizontal'
                                    dataSource={this.state.session?.waiting_list}
                                    renderItem={(player) => (
                                        <List.Item
                                            actions={[
                                                <Button
                                                    className='btn-success'
                                                    onClick={() => {
                                                        this.add_player_to_list(player, 'confirmed');
                                                        this.setState({
                                                            fill_slot_modal: false,
                                                        });
                                                    }}
                                                    type='primary'
                                                >
                                                    Bæta við viðburð
                                                </Button>,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <TeamShirt
                                                        style={{width: 35}}
                                                        className='shirt'
                                                        kit_color={this.state.session?.event?.team?.away_kit_color}
                                                        kit_secondary_color={this.state.session?.event?.team?.away_kit_secondary_color}
                                                        kit_template={this.state.session?.event?.team?.away_kit_template}
                                                    ></TeamShirt>
                                                }
                                                title={<div className='capitalize'>{player.user?.signature_name || player.unconfirmed_name}</div>}
                                            ></List.Item.Meta>
                                        </List.Item>
                                    )}
                                />

                                <h3 className='text-center'>Varamannabekkurinn</h3>
                                <List
                                    className='text-left'
                                    locale={{emptyText: 'Varamannabekurinn er tómur :/'}}
                                    itemLayout='horizontal'
                                    dataSource={this.state.session?.event?.team?.substitutes}
                                    renderItem={(player) => {
                                        const waiting_list = !!this.state.session.waiting_list.filter((pl) => pl.id === player.id).length;
                                        const confirmed_list = !!this.state.session.confirmed.filter((pl) => pl.id === player.id).length;

                                        if (!waiting_list && !confirmed_list) {
                                            return (
                                                <List.Item
                                                    actions={[
                                                        <Button
                                                            onClick={() => {
                                                                this.add_player_to_list(player, 'confirmed');
                                                                this.setState({
                                                                    fill_slot_modal: false,
                                                                });
                                                            }}
                                                            className='btn-success'
                                                            type='primary'
                                                        >
                                                            {'Bæta við viðburð'}
                                                        </Button>,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <TeamShirt
                                                                style={{width: 35}}
                                                                className='shirt'
                                                                kit_color={this.state.session?.event?.team?.away_kit_color}
                                                                kit_secondary_color={this.state.session?.event?.team?.away_kit_secondary_color}
                                                                kit_template={this.state.session?.event?.team?.away_kit_template}
                                                            ></TeamShirt>
                                                        }
                                                        title={
                                                            <div>
                                                                <div className='capitalize'>{player.user?.signature_name || player.unconfirmed_name}</div>
                                                            </div>
                                                        }
                                                    ></List.Item.Meta>
                                                </List.Item>
                                            );
                                        }
                                    }}
                                />

                                <h3 className='text-center'>Leikmannalistinn</h3>
                                <List
                                    className='text-left'
                                    locale={{emptyText: 'Það eru engir leikmenn til að sýna'}}
                                    itemLayout='horizontal'
                                    dataSource={this.state.session?.event?.team?.players}
                                    renderItem={(player) => {
                                        const cant_make_it = !!this.state.session.cant_make_it.filter((pl) => pl.id === player.id).length;

                                        return (
                                            !!!this.state.session.confirmed.filter((pl) => pl.id === player.id).length && (
                                                <List.Item
                                                    actions={[
                                                        <Button
                                                            onClick={() => {
                                                                this.add_player_to_list(player, 'confirmed');
                                                                this.setState({
                                                                    fill_slot_modal: false,
                                                                });
                                                            }}
                                                            className='btn-success'
                                                            type='primary'
                                                        >
                                                            {cant_make_it ? 'Breyta í mætir' : 'Bæta við viðburð'}
                                                        </Button>,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <TeamShirt
                                                                style={{width: 35}}
                                                                className='shirt'
                                                                kit_color={this.state.session?.event?.team?.main_kit_color}
                                                                kit_secondary_color={this.state.session?.event?.team?.main_kit_secondary_color}
                                                                kit_template={this.state.session?.event?.team?.main_kit_template}
                                                            ></TeamShirt>
                                                        }
                                                        title={
                                                            <div>
                                                                <div className='capitalize'>{player.user?.signature_name || player.unconfirmed_name}</div>
                                                                {cant_make_it && (
                                                                    <div className='errorColor'>
                                                                        <strong>Mætir ekki</strong>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        }
                                                    ></List.Item.Meta>
                                                </List.Item>
                                            )
                                        );
                                    }}
                                />
                            </Space>
                        </Modal>

                        {this.state.divide_into_teams_modal && this.state.session?.confirmed?.length && (
                            <DivideIntoTeamsModal
                                team={this.state.session?.event?.team}
                                visible={this.state.divide_into_teams_modal}
                                onCancel={() => {
                                    this.setState({divide_into_teams_modal: false});
                                }}
                                players={this.state.session?.confirmed}
                            ></DivideIntoTeamsModal>
                        )}

                        {this.state.send_report_modal && (
                            <SendSessionReportModal
                                visible={!!this.state.send_report_modal}
                                session={this.state.send_report_modal}
                                onCancel={() => {
                                    this.setState({send_report_modal: false});
                                }}
                            ></SendSessionReportModal>
                        )}

                        {!!this.state.edit_session_modal && (
                            <EditSessionModal
                                visible={!!this.state.edit_session_modal}
                                onCancel={() => {
                                    this.setState({edit_session_modal: false});
                                }}
                                session={this.state.edit_session_modal}
                                onSuccess={(data) => {
                                    const new_data = {date: data.date};
                                    if (data.location) new_data.location = data.location;

                                    this.setState({edit_session_modal: false, session: {...this.state.session, ...new_data}});
                                }}
                            ></EditSessionModal>
                        )}

                        {this.state.edit_player && (
                            <EditPlayerModal
                                visible={this.state.edit_player_modal}
                                onCancel={() => {
                                    this.setState({edit_player_modal: false, edit_player: null});
                                }}
                                player={this.state.edit_player}
                                onClick={(list_name) => {
                                    this.add_player_to_list(this.state.edit_player, list_name);
                                    this.setState({edit_player: null, edit_player_modal: false});
                                }}
                                team={this.state.session?.event?.team}
                                is_substitute={this.is_substitute_player(this.state.edit_player)}
                                is_og_player={this.is_og_player(this.state.edit_player)}
                                title='Breyta mætingu'
                                defaultValue={
                                    this.state.session.confirmed.some((player) => {
                                        return this.state.edit_player.id === player.id;
                                    })
                                        ? 'confirmed'
                                        : 'cant_make_it'
                                }
                            ></EditPlayerModal>
                        )}
                    </Content>
                </Layout>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, {getUser})(Session);
