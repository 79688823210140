import React, {Component} from 'react';
import moment from 'moment';
import {Form, Input, Button, Select, Card, Layout, Row, Col, TimePicker, InputNumber, Space, DatePicker, message} from 'antd';
import {CheckCircleTwoTone} from '@ant-design/icons';

import Axios from 'axios';
import GooglePlacesSearch from '../../components/GooglePlacesSearch/GooglePlacesSearch';

const slugify = require('slugify');
const {Content} = Layout;

export default class CreateEvent extends Component {
    formRef = React.createRef();

    state = {
        loading: true,
        frequency_type: 'WEEKLY',
        categories: [],
    };

    componentDidMount() {
        Axios.get('/api/category/').then((rsp) => {
            this.setState({
                loading: false,
                categories: rsp.data.results,
            });
        });

        Axios.get('/api/location/').then((rsp) => {
            this.setState({
                locations: rsp.data.results,
            });
        });

        Axios.get('/api/team/' + window.uuid).then((rsp) => {
            this.setState({
                team: rsp.data,
            });
        });
    }

    onFreqencyChange() {
        this.setState({
            frequency_type: this.formRef?.current?.getFieldValue('frequency_type'),
        });
    }

    get_or_create_category(values) {
        const cateogry_arr = this.state.categories.filter((category) => category.slug === values.category[0]);

        if (cateogry_arr.length) {
            return new Promise((resolve) => {
                resolve({data: {id: cateogry_arr[0].id}});
            });
        } else {
            const new_category = {slug: slugify(values.category[0]), title: values.category[0]};

            return Axios.post('/api/category/create/', new_category);
        }
    }

    get_or_create_location(values) {
        const place = values.place;
        const place_arr = this.state.locations.filter((location) => location.google_place_id === place.placeId);

        if (place_arr.length) {
            return new Promise((resolve) => {
                resolve({data: {id: place_arr[0].id}});
            });
        } else {
            const new_place = {
                title: place.formattedSuggestion?.mainText,
                description: place.description,
                google_place_id: place.placeId,
                google_place_main_text: place.formattedSuggestion?.mainText,
                google_place_secondary_text: place.formattedSuggestion?.secondaryText,
            };

            return Axios.post('/api/location/create/', new_place);
        }
    }

    onFinish = (values) => {
        this.setState({
            loading: true,
        });

        Promise.all([this.get_or_create_category(values), this.get_or_create_location(values)]).then((rsp) => {
            let params = JSON.parse(JSON.stringify(values));
            params.location = rsp[1].data.id;
            params.category = rsp[0].data.id;
            params.team = this.state.team.id;
            params.max_player_count = params.min_player_count;
            params.time_of_day = moment(params.time_of_day).format('HH:mm');
            params.description = params.description || '';

            Axios.post('/api/event/create/', params)
                .then((rsp) => {
                    message.success('Viðburðurinn hefur verið stofnaður, vúhúúú');
                    setTimeout(() => {
                        window.top.location.href = '/lid/' + window.uuid + '/';
                    }, 500);
                })
                .catch((err) => {
                    message.error('Úps, eitthvað fór úrskeiðis :(');
                });
        });
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    timePickerBlur(time) {
        this.formRef.current.setFieldsValue({
            time_of_day: time,
        });
    }

    new_category(arr) {
        let list = arr || [];
        list = list.length ? (list.length === 2 ? [list[1]] : [list[0]]) : list;

        this.formRef.current.setFieldsValue({
            category: list,
        });
    }

    onAddressSelect(obj) {
        this.formRef.current.setFieldsValue({
            place: obj,
        });
        this.setState({
            address: obj,
        });
    }

    render() {
        return (
            <>
                <Layout>
                    <Content>
                        <Row gutter={[10, 10]} style={{margin: 10}}>
                            <Col xs={24} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form ref={this.formRef} layout='vertical' name='basic' initialValues={{remember: true}} onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
                                    <Space direction='vertical' style={{display: 'flex'}}>
                                        <Card loading={this.state.loading} title='Nýr viðburður - Grunn upplýsingar' type='inner'>
                                            <Form.Item label='Titill (t.d "Æfing")' name='title' rules={[{required: true, message: 'Þú verður að gefa viðburðinum nafn.'}]}>
                                                <Input autoComplete='new-password' />
                                            </Form.Item>
                                            <Form.Item name='description' label='Lýsing (ekki nauðsynlegt)'>
                                                <Input.TextArea />
                                            </Form.Item>

                                            {this.state.categories && (
                                                <Form.Item initialValue={['soccer']} name='category' label='Flokkur' rules={[{required: true, message: 'Þú verður að velja flokk.'}]}>
                                                    <Select optionFilterProp='label' onChange={this.new_category.bind(this)} mode='tags' style={{width: '100%'}}>
                                                        {this.state.categories.map((category, key) => {
                                                            return (
                                                                <Select.Option value={category.slug} label={category.title} key={key}>
                                                                    {category.title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            )}

                                            <Form.Item label='Staðsetning' name='place' rules={[{required: true, message: 'Þú verður að velja staðsetningu.'}]}>
                                                {this.state.address && (
                                                    <div style={{border: '1px solid #eaeaea', padding: '10px', borderRadius: '15px', marginBottom: '10px', background: '#fbfbfb'}}>
                                                        <h3>
                                                            <CheckCircleTwoTone twoToneColor='#52c41a' />
                                                            <span> </span>
                                                            {this.state.address.formattedSuggestion?.mainText}
                                                        </h3>
                                                        <h4>{this.state.address.formattedSuggestion?.secondaryText}</h4>
                                                    </div>
                                                )}
                                                <GooglePlacesSearch onAddressSelect={this.onAddressSelect.bind(this)} value='place'></GooglePlacesSearch>
                                            </Form.Item>
                                        </Card>

                                        <Card loading={this.state.loading} title='Tímasetningar og leikmenn' type='inner'>
                                            <Form.Item name='frequency_type' initialValue='WEEKLY' label='Reglubundinn'>
                                                <Select onChange={this.onFreqencyChange.bind(this)} style={{width: '100%'}}>
                                                    <Select.Option value='ONE_TIME'>Bara einn viðburður</Select.Option>
                                                    <Select.Option value='WEEKLY'>Vikulega</Select.Option>
                                                    {/* <Select.Option value='monthly'>Mánaðarlega</Select.Option>
                                                    <Select.Option value='yearly'>Árlega</Select.Option> */}
                                                </Select>
                                            </Form.Item>

                                            {this.state.frequency_type === 'WEEKLY' && (
                                                <Form.Item name='day_of_week' initialValue='MONDAY' label='Dagur'>
                                                    <Select style={{width: '100%'}}>
                                                        <Select.Option value='MONDAY'>Mánudagur</Select.Option>
                                                        <Select.Option value='TUESDAY'>Þriðjudagur</Select.Option>
                                                        <Select.Option value='WEDNESDAY'>Miðvikudagur</Select.Option>
                                                        <Select.Option value='THURSDAY'>Fimmtudagur</Select.Option>
                                                        <Select.Option value='FRIDAY'>Föstudagur</Select.Option>
                                                        <Select.Option value='SATURDAY'>Laugardagur</Select.Option>
                                                        <Select.Option value='SUNDAY'>Sunnudagur</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            )}

                                            {this.state.frequency_type === 'ONE_TIME' && (
                                                <Form.Item label='Dagseting' name='one_time_date' rules={[{required: true, message: 'Þú verður að velja dagsetningu.'}]}>
                                                    <DatePicker placeholder='Veldu dagsetningu' format='DD MMM YYYY' />
                                                </Form.Item>
                                            )}

                                            <Form.Item label='Tími dags' name='time_of_day' initialValue={moment('19:30', 'HH:mm')} rules={[{required: true, message: 'Þú verður að velja tíma dags.'}]}>
                                                <TimePicker allowClear={false} showNow={false} onSelect={this.timePickerBlur.bind(this)} format='HH:mm' placeholder='Veltu tíma' />
                                            </Form.Item>
                                        </Card>

                                        <Card loading={this.state.loading} title='Fjöldi plássa' type='inner'>
                                            <Form.Item name='ideal_player_count' label='Heildarfjöldi plássa' initialValue={10} rules={[{required: true, message: 'Þú verður að ákveða fjölda plássa'}]}>
                                                <InputNumber autoComplete='new-password' />
                                            </Form.Item>

                                            <Form.Item
                                                name='min_player_count'
                                                label='Lágmarksfjöldi svo að viðburður eigi sér stað'
                                                initialValue={8}
                                                rules={[{required: true, message: 'Þú verður að ákveða lágmarksfjölda plássa'}]}
                                            >
                                                <InputNumber autoComplete='new-password' min={1} />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button type='primary' size='large' loading={this.state.loading} block htmlType='submit'>
                                                    Vista
                                                </Button>
                                            </Form.Item>
                                        </Card>
                                    </Space>
                                </Form>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </>
        );
    }
}
