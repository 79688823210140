// CSS
import './App.less';
//BASIC REACT STUFF
import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

//LIBRARIES
import {Layout} from 'antd';
import JavascriptTimeAgo from 'javascript-time-ago';
import is from 'javascript-time-ago/locale/is';
import axios from 'axios';

// COMPONENTS
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

// REDUX
import store from './store';
import {Provider} from 'react-redux';

// PAGES
import Frontpage from './Pages/Frontpage/Frontpage';
import About from './Pages/About/About';
import Team from './Pages/Team/Team';
import TeamKit from './Pages/TeamKit/TeamKit';
import Session from './Pages/Session/Session';
import CreateEvent from './Pages/CreateEvent/CreateEvent';
import CreateTeam from './Pages/CreateTeam/CreateTeam';
import Profile from './Pages/Profile/Profile';
import PlayerDetails from './Pages/PlayerDetails/PlayerDetails';

import EventNotifications from './Pages/EventNotifications/EventNotifications';

//LIBRARIES SETTINGS
JavascriptTimeAgo.addLocale(is);
axios.defaults.headers.common['x-csrftoken'] = document.getElementsByName('csrfmiddlewaretoken')[0].value;

export default class App extends Component {
    render() {
        return (
            <>
                <Provider store={store}>
                    <Router>
                        <Layout>
                            <Header></Header>
                            <Layout id='mainContainer'>
                                <Layout>
                                    <>
                                        <Switch>
                                            <Route path='/' exact>
                                                <Frontpage></Frontpage>
                                            </Route>

                                            <Route path='/umokkur'>
                                                <About></About>
                                            </Route>

                                            <Route path='/lid'>
                                                <Team></Team>
                                            </Route>

                                            <Route path='/buningar'>
                                                <TeamKit></TeamKit>
                                            </Route>

                                            <Route path='/timi'>
                                                <Session></Session>
                                            </Route>

                                            <Route path='/stillingar'>
                                                <Profile></Profile>
                                            </Route>

                                            <Route path='/leikmadur'>
                                                <PlayerDetails></PlayerDetails>
                                            </Route>

                                            <Route path='/nyttlid'>
                                                <CreateTeam></CreateTeam>
                                            </Route>

                                            <Route path='/stofna_vidburd'>
                                                <CreateEvent></CreateEvent>
                                            </Route>

                                            <Route path='/aminningar'>
                                                <EventNotifications></EventNotifications>
                                            </Route>
                                        </Switch>
                                    </>
                                </Layout>
                            </Layout>

                            <Footer>
                                <strong>vantaribolta.is</strong>- Hannað og forritað með ástríðu af{' '}
                                <a href='https://pleysson.com/' target='_blank' rel='noopener noreferrer'>
                                    <img style={{width: 50}} src='https://pleysson.com/static/images/logo/big.png' alt='Pleysson logo' />
                                </a>
                            </Footer>
                        </Layout>
                    </Router>
                </Provider>
            </>
        );
    }
}
