import React, {Component} from 'react';

export default class Declensions extends Component {
    state = {
        word: '',
    };

    componentDidMount() {
        const plural = this.props.plural;

        const words = {
            MONDAY: {
                nefnifall: plural ? 'mánudagar' : 'mánudagur',
                tholfall: plural ? 'mánudaga' : 'mánudag',
                thagufall: plural ? 'mánudögum' : 'mánudegi',
                eignarfall: plural ? 'mánudaga' : 'mánudags',
            },
            TUESDAY: {
                nefnifall: plural ? 'þriðjudagar' : 'þriðjudagur',
                tholfall: plural ? 'þriðjudaga' : 'þriðjudag',
                thagufall: plural ? 'þriðjudögum' : 'þriðjudegi',
                eignarfall: plural ? 'þriðjudaga' : 'þriðjudags',
            },
            WEDNESDAY: {
                nefnifall: plural ? 'miðvikudagar' : 'miðvikudagur',
                tholfall: plural ? 'miðvikudaga' : 'miðvikudag',
                thagufall: plural ? 'miðvikudögum' : 'miðvikudegi',
                eignarfall: plural ? 'miðvikudaga' : 'miðvikudags',
            },
            THURSDAY: {
                nefnifall: plural ? 'fimmtudagar' : 'fimmtudagur',
                tholfall: plural ? 'fimmtudaga' : 'fimmtudag',
                thagufall: plural ? 'fimmtudögum' : 'fimmtudegi',
                eignarfall: plural ? 'fimmtudaga' : 'fimmtudags',
            },
            FRIDAY: {
                nefnifall: plural ? 'föstudagar' : 'föstudagur',
                tholfall: plural ? 'föstudaga' : 'föstudag',
                thagufall: plural ? 'föstudögum' : 'föstudegi',
                eignarfall: plural ? 'föstudaga' : 'föstudags',
            },
            SATURDAY: {
                nefnifall: plural ? 'laugardagar' : 'laugardagur',
                tholfall: plural ? 'laugardaga' : 'laugardag',
                thagufall: plural ? 'laugardögum' : 'laugardegi',
                eignarfall: plural ? 'laugardaga' : 'laugardags',
            },
            SUNDAY: {
                nefnifall: plural ? 'sunnudagar' : 'sunnudagur',
                tholfall: plural ? 'sunnudaga' : 'sunnudag',
                thagufall: plural ? 'sunnudögum' : 'sunnudegi',
                eignarfall: plural ? 'sunnudaga' : 'sunnudags',
            },
        };

        this.setState({
            word: (words[this.props.children] && words[this.props.children][this.props.declenasion || 'nefnifall']) || this.props.children,
        });
    }

    render() {
        return <div style={this.props.style}>{this.state.word}</div>;
    }
}
