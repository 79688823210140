import React, {Component} from 'react';
import {Modal, Checkbox, Form, TimePicker, DatePicker, Button, message} from 'antd';
import {CheckCircleTwoTone} from '@ant-design/icons';
import GooglePlacesSearch from '../../../components/GooglePlacesSearch/GooglePlacesSearch';
import {get_or_create_location} from '../../../utils';
import Axios from 'axios';

export default class EditSessionModal extends Component {
    state = {};

    formRef = React.createRef();

    componentDidMount() {
        this.load_google_maps();
    }

    load_google_maps(callback) {
        const existing = document.getElementById('googleMaps');

        if (!existing) {
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBhzlST8S63iZiYfRDjSbYTXw8CjlMu_Os&libraries=places&language=is';
            script.id = 'googleMaps';
            document.body.appendChild(script);
            script.onload = () => {
                this.setState({google_loaded: true});
            };
        } else {
            this.setState({google_loaded: true});
        }
    }

    onAddressSelect(obj) {
        this.formRef.current.setFieldsValue({
            place: obj,
        });
        this.setState({
            address: obj,
        });
    }

    timePickerBlur(time) {
        this.formRef.current.setFieldsValue({
            time_of_day: time,
        });
    }

    onFinish(values) {
        this.setState({loading: true});
        let date = null;

        if (values.time_of_day && values.one_time_date) {
            date = values.one_time_date;
            const time = values.time_of_day;

            date = date.set({
                hour: time.get('hour'),
                minute: time.get('minute'),
                second: 0,
                millisecond: 0,
            });
        }

        const patch_place = (obj, new_location) => {
            Axios.patch(`/api/session/${this.props.session.uuid}/update/`, obj).then((rsp) => {
                this.props.onSuccess({
                    date: rsp.data.date,
                    location: new_location,
                });
                message.success('Tókst');
            });
        };

        if (values.place) {
            const res = get_or_create_location(values.place);
            res.then((location) => {
                const update_obj = date ? {date: date.toISOString(), location: location.id} : {location: location.id};
                patch_place(update_obj, location);
            });
        } else {
            patch_place({date: date.toISOString()});
        }
    }

    render() {
        return (
            <Modal maskClosable={false} footer={null} visible={this.props.visible} onCancel={this.props.onCancel} onSuccess={this.props.onSuccess}>
                {!this.state.delete_session && (
                    <>
                        <h3>Hverju viltu breyta</h3>

                        <Checkbox
                            onClick={() => {
                                this.setState({change_date: !this.state.change_date});
                            }}
                            checked={this.state.change_date}
                        >
                            Tímasetningu
                        </Checkbox>
                        <Checkbox
                            onClick={() => {
                                this.setState({change_location: !this.state.change_location});
                            }}
                            checked={this.state.change_location}
                        >
                            Staðsetningunni
                        </Checkbox>
                        <hr></hr>
                        <Form ref={this.formRef} layout='vertical' onFinish={this.onFinish.bind(this)}>
                            {this.state.change_location && this.state.google_loaded && (
                                <Form.Item label='Staðsetning' name='place' rules={[{required: true, message: 'Þú verður að velja staðsetningu.'}]}>
                                    <>
                                        {this.state.address && (
                                            <div style={{border: '1px solid #eaeaea', padding: '10px', borderRadius: '15px', marginBottom: '10px', background: '#fbfbfb'}}>
                                                <h3>
                                                    <CheckCircleTwoTone twoToneColor='#52c41a' />
                                                    <span> </span>
                                                    {this.state.address?.formattedSuggestion?.mainText}
                                                </h3>
                                                <h4>{this.state.address?.formattedSuggestion?.secondaryText}</h4>
                                            </div>
                                        )}
                                        <GooglePlacesSearch onAddressSelect={this.onAddressSelect.bind(this)} value='place'></GooglePlacesSearch>
                                    </>
                                </Form.Item>
                            )}

                            {this.state.change_date && (
                                <>
                                    <Form.Item label='Dagseting' name='one_time_date' rules={[{required: true, message: 'Þú verður að velja dagsetningu.'}]}>
                                        <DatePicker placeholder='Veldu dagsetningu' format='DD MMM YYYY' />
                                    </Form.Item>

                                    <Form.Item label='Tími dags' name='time_of_day' rules={[{required: true, message: 'Þú verður að velja tíma dags.'}]}>
                                        <TimePicker allowClear={false} showNow={false} onSelect={this.timePickerBlur.bind(this)} format='HH:mm' placeholder='Veltu tíma' />
                                    </Form.Item>
                                </>
                            )}

                            {(this.state.change_date || this.state.change_location) && (
                                <Form.Item>
                                    <Button size='large' block htmlType='submit' loading={this.state.loading} type='primary'>
                                        Uppfæra
                                    </Button>
                                </Form.Item>
                            )}
                        </Form>
                    </>
                )}
            </Modal>
        );
    }
}
