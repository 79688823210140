import React, {Component} from 'react';
import {Layout, Card, Col, Row, Space, Select, Button, message} from 'antd';

import './TeamKit.less';
import Axios from 'axios';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import TeamShirt from '../../components/TeamShirt/TeamShirt';

const {Content} = Layout;
const {Option} = Select;

export default class TeamKit extends Component {
    state = {
        loading: true,
        team: {},
    };

    componentDidMount() {
        Axios.get('/api/team/' + window.uuid + '/').then((rsp) => {
            this.setState({
                team: rsp.data,
                loading: false,
            });
        });
    }

    submitKit() {
        this.setState({loading: true});
        const {main_kit_color, main_kit_secondary_color, main_kit_template, away_kit_color, away_kit_secondary_color, away_kit_template} = this.state.team;

        Axios.patch('/api/team/' + window.uuid + '/update/', {
            main_kit_color,
            main_kit_secondary_color,
            main_kit_template,
            away_kit_color,
            away_kit_secondary_color,
            away_kit_template,
        })
            .then((rsp) => {
                this.setState({loading: false});
                message.success('Vúhúúú, tókst. Búningarnir hafa verið vistaðir!');
            })
            .catch((err) => {
                if (err.response?.status === 403) {
                    message.error('Þú hefur ekki leyfi til að framkvæma þessa aðgerð!');
                } else {
                    message.error('Úps, eitthvað fór úrskeiðis :(');
                }
            });
    }

    showPlayerModal = () => {
        this.setState({
            playerModalVisible: true,
        });
    };

    handleOk = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({loading: false, playerModalVisible: false});
        }, 3000);
    };

    setKitColor = (key, color) => {
        let team = {...this.state.team};
        team[key] = color;
        this.setState({team: team});
    };

    handleKitChange = (key, kit) => {
        const team = {...this.state.team};
        team[key] = kit;
        this.setState({
            team,
        });
    };

    render() {
        return (
            <>
                <Layout id='team_kit'>
                    <Content>
                        <Row type='flex' gutter={[10, 10]} style={{margin: 10}}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={8}>
                                <Space style={{display: 'flex'}} direction='vertical'>
                                    <Card loading={this.state.loading} title={'Aðalbúningur'}>
                                        <Space direction='vertical' style={{display: 'flex'}}>
                                            {this.state.team.away_kit_template}
                                            <Row>
                                                <Space className='flex-space'>
                                                    <Select
                                                        className='shirt_template_select'
                                                        dropdownClassName='grid_select'
                                                        defaultValue={this.state.team.main_kit_template}
                                                        onChange={this.handleKitChange.bind(this, 'main_kit_template')}
                                                    >
                                                        <Option value='1'>
                                                            <img src='/static/images/shirt_templates/1.svg' alt='' />
                                                        </Option>
                                                        <Option value='2'>
                                                            <img src='/static/images/shirt_templates/2.svg' alt='' />
                                                        </Option>
                                                        <Option value='3'>
                                                            <img src='/static/images/shirt_templates/3.svg' alt='' />
                                                        </Option>
                                                        <Option value='4'>
                                                            <img src='/static/images/shirt_templates/4.svg' alt='' />
                                                        </Option>
                                                        <Option value='5'>
                                                            <img src='/static/images/shirt_templates/5.svg' alt='' />
                                                        </Option>
                                                        <Option value='6'>
                                                            <img src='/static/images/shirt_templates/6.svg' alt='' />
                                                        </Option>
                                                        <Option value='7'>
                                                            <img src='/static/images/shirt_templates/7.svg' alt='' />
                                                        </Option>
                                                        <Option value='8'>
                                                            <img src='/static/images/shirt_templates/8.svg' alt='' />
                                                        </Option>
                                                    </Select>
                                                    <ColorPicker className='shirt_template_color_picker' color={this.state.team.main_kit_color} onChange={this.setKitColor.bind(this, 'main_kit_color')}></ColorPicker>
                                                    <ColorPicker
                                                        className='shirt_template_color_picker'
                                                        color={this.state.team.main_kit_secondary_color}
                                                        onChange={this.setKitColor.bind(this, 'main_kit_secondary_color')}
                                                    ></ColorPicker>
                                                </Space>
                                            </Row>

                                            <hr></hr>
                                            <div style={{textAlign: 'center'}}>
                                                <TeamShirt
                                                    style={{width: '80%', margin: 'auto'}}
                                                    className='shirt'
                                                    kit_color={this.state.team.main_kit_color}
                                                    kit_secondary_color={this.state.team.main_kit_secondary_color}
                                                    kit_template={this.state.team.main_kit_template}
                                                ></TeamShirt>
                                            </div>
                                        </Space>
                                    </Card>
                                </Space>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={8}>
                                <Space style={{display: 'flex'}} direction='vertical'>
                                    <Card loading={this.state.loading} title={'Varabúningur'}>
                                        <Space direction='vertical' style={{display: 'flex'}}>
                                            {this.state.team.away_kit_template}
                                            <Row>
                                                <Space className='flex-space'>
                                                    <Select
                                                        className='shirt_template_select'
                                                        dropdownClassName='grid_select'
                                                        defaultValue={this.state.team.away_kit_template}
                                                        onChange={this.handleKitChange.bind(this, 'away_kit_template')}
                                                    >
                                                        <Option value='1'>
                                                            <img src='/static/images/shirt_templates/1.svg' alt='' />
                                                        </Option>
                                                        <Option value='2'>
                                                            <img src='/static/images/shirt_templates/2.svg' alt='' />
                                                        </Option>
                                                        <Option value='3'>
                                                            <img src='/static/images/shirt_templates/3.svg' alt='' />
                                                        </Option>
                                                        <Option value='4'>
                                                            <img src='/static/images/shirt_templates/4.svg' alt='' />
                                                        </Option>
                                                        <Option value='5'>
                                                            <img src='/static/images/shirt_templates/5.svg' alt='' />
                                                        </Option>
                                                        <Option value='6'>
                                                            <img src='/static/images/shirt_templates/6.svg' alt='' />
                                                        </Option>
                                                        <Option value='7'>
                                                            <img src='/static/images/shirt_templates/7.svg' alt='' />
                                                        </Option>
                                                        <Option value='8'>
                                                            <img src='/static/images/shirt_templates/8.svg' alt='' />
                                                        </Option>
                                                    </Select>
                                                    <ColorPicker className='shirt_template_color_picker' color={this.state.team.away_kit_color} onChange={this.setKitColor.bind(this, 'away_kit_color')}></ColorPicker>
                                                    <ColorPicker
                                                        className='shirt_template_color_picker'
                                                        color={this.state.team.away_kit_secondary_color}
                                                        onChange={this.setKitColor.bind(this, 'away_kit_secondary_color')}
                                                    ></ColorPicker>
                                                </Space>
                                            </Row>

                                            <hr></hr>

                                            <div style={{textAlign: 'center'}}>
                                                <TeamShirt
                                                    style={{width: '80%', margin: 'auto'}}
                                                    className='shirt'
                                                    kit_color={this.state.team.away_kit_color}
                                                    kit_secondary_color={this.state.team.away_kit_secondary_color}
                                                    kit_template={this.state.team.away_kit_template}
                                                ></TeamShirt>
                                            </div>
                                        </Space>
                                    </Card>
                                </Space>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={16}>
                                <Button loading={this.loading} onClick={this.submitKit.bind(this)} block size='large' type='primary' className='btn-success'>
                                    Vista
                                </Button>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </>
        );
    }
}
