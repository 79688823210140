import React, {Component} from 'react';
import {Button, Card, Space, Modal, List} from 'antd';
import {RedoOutlined} from '@ant-design/icons';
import TeamShirt from '../../../components/TeamShirt/TeamShirt';

export default class DivideIntoTeamsModal extends Component {
    state = {
        number_of_teams: 2,
        teams: [],
    };

    componentDidMount() {
        this.setState(
            {
                players: this.props.players,
            },
            () => {
                this.split_teams();
            }
        );
    }

    shuffle(arr) {
        var currentIndex = arr.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = arr[currentIndex];
            arr[currentIndex] = arr[randomIndex];
            arr[randomIndex] = temporaryValue;
        }

        return arr;
    }

    split_teams = () => {
        if (this.state.players?.length) {
            /* nr of teams = 2 */

            const teams = JSON.parse(JSON.stringify(Array(this.state.number_of_teams).fill([])));

            this.shuffle(this.state.players).forEach((player, index) => {
                const test = teams.sort((team, prevTeam) => {
                    return team.length < prevTeam.length ? -1 : 1;
                });
                test[0].push(player);
            });

            this.setState((prevstate) => {
                return {teams};
            });
        }
    };

    render() {
        return (
            <>
                <Modal title='Handahófskennd liðaskipting' onCancel={this.props.onCancel} visible={this.props.visible} footer={null}>
                    {this.state.teams && (
                        <>
                            <Space direction='vertical' style={{display: 'flex'}}>
                                <Space>
                                    <Button onClick={this.split_teams}>
                                        <RedoOutlined />
                                    </Button>

                                    {this.state.number_of_teams < this.state.players?.length && (
                                        <Button
                                            onClick={() => {
                                                this.setState({number_of_teams: this.state.number_of_teams + 1}, () => {
                                                    this.split_teams();
                                                });
                                            }}
                                            type='dashed'
                                        >
                                            + Bæta við liði
                                        </Button>
                                    )}

                                    {this.state.number_of_teams > 2 && (
                                        <Button
                                            onClick={() => {
                                                this.setState({number_of_teams: this.state.number_of_teams - 1}, () => {
                                                    this.split_teams();
                                                });
                                            }}
                                            type='dashed'
                                        >
                                            - Fækka liðum
                                        </Button>
                                    )}
                                </Space>

                                {this.state.teams.map((team, team_key) => {
                                    return (
                                        <Card key={team_key} title={this.state.number_of_teams === 2 && team_key === 1 ? 'Lið ' + (team_key + 1) + ' (vesti)' : 'Lið ' + (team_key + 1)} type='inner'>
                                            {team.map((player, key) => {
                                                return team_key % 2 ? (
                                                    <List.Item.Meta
                                                        key={key}
                                                        avatar={
                                                            <TeamShirt
                                                                style={{width: 35}}
                                                                className='shirt'
                                                                kit_color={this.props.team.away_kit_color}
                                                                kit_secondary_color={this.props.team.away_kit_secondary_color}
                                                                kit_template={this.props.team.away_kit_template}
                                                            ></TeamShirt>
                                                        }
                                                        title={<div>{player.user?.signature_name || player.unconfirmed_name}</div>}
                                                    ></List.Item.Meta>
                                                ) : (
                                                    <List.Item.Meta
                                                        key={key}
                                                        avatar={
                                                            <TeamShirt
                                                                style={{width: 35}}
                                                                className='shirt'
                                                                kit_color={this.props.team.main_kit_color}
                                                                kit_secondary_color={this.props.team.main_kit_secondary_color}
                                                                kit_template={this.props.team.main_kit_template}
                                                            ></TeamShirt>
                                                        }
                                                        title={<div>{player.user?.signature_name || player.unconfirmed_name}</div>}
                                                    ></List.Item.Meta>
                                                );
                                            })}
                                        </Card>
                                    );
                                })}
                                {/* <Card title='Lið 1' type='inner'>
                                    {this.state.team_one.map((player, key) => {
                                        return (
                                            <List.Item.Meta
                                                key={key}
                                                avatar={
                                                    <TeamShirt
                                                        style={{ width: 35 }}
                                                        className='shirt'
                                                        kit_color={this.props.team.away_kit_color}
                                                        kit_secondary_color={this.props.team.away_kit_secondary_color}
                                                        kit_template={this.props.team.away_kit_template}
                                                    ></TeamShirt>
                                                }
                                                title={<div>{player.user?.signature_name || player.unconfirmed_name}</div>}
                                            ></List.Item.Meta>
                                        );
                                    })}
                                </Card>
                                <Card title='Lið 2 (í vesti)' type='inner'>
                                    {this.state.team_two.map((player, key) => {
                                        return (
                                            <List.Item.Meta
                                                key={key}
                                                avatar={
                                                    <TeamShirt
                                                        style={{ width: 35 }}
                                                        className='shirt'
                                                        kit_color={this.props.team.main_kit_color}
                                                        kit_secondary_color={this.props.team.main_kit_secondary_color}
                                                        kit_template={this.props.team.main_kit_template}
                                                    ></TeamShirt>
                                                }
                                                title={<div>{player.user?.signature_name || player.unconfirmed_name}</div>}
                                            ></List.Item.Meta>
                                        );
                                    })}
                                </Card> */}
                            </Space>
                        </>
                    )}
                </Modal>
            </>
        );
    }
}
