import React, {Component} from 'react';
import {Button, Modal, Space, message} from 'antd';
import Axios from 'axios';

export default class DeleteEventModal extends Component {
    formRef = React.createRef();

    state = {
        loading: true,
    };

    componentDidMount() {
        this.setState({
            loading: false,
        });
    }

    confirm_delete() {
        this.setState({loading: true});

        Axios.delete('/api/notification/delete/' + this.props.notification.id).then((rsp) => {
            this.props.add_remove_notification(this.props.notification, 'remove');
            this.props.onCancel();
            message.success('Tókst, áminningu hefur verið eytt!');
        });
    }

    render() {
        return (
            <Modal footer={null} visible={this.props.visible} title='Alveg viss?' onCancel={this.props.onCancel}>
                <Space>
                    <Button
                        onClick={() => {
                            this.confirm_delete();
                        }}
                    >
                        Já, henda!
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.onCancel();
                        }}
                    >
                        Hætta við
                    </Button>
                </Space>
            </Modal>
        );
    }
}
