import React, {useState, useEffect, useRef} from 'react';
import {Input, Form, message, Button, Tooltip} from 'antd';
import Axios from 'axios';
import Avatar from 'antd/lib/avatar/avatar';
import './ChatBox.less';
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';

export default function ChatBox(props) {
    const webSocket = useRef(null);
    const [form] = Form.useForm();
    const [messages, setMessages] = useState([]);
    const [websocketMessages, setWebsocketMessages] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [nextPage, setNextPage] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        webSocket.current = new WebSocket(`wss://${window.location.host}/ws/chat/${props.session.uuid}/`);

        /* webSocket.current.onopen = (e) => {
            webSocket.current.send(JSON.stringify({action: 'get_messages'}));
        }; */

        webSocket.current.onmessage = (message) => {
            const data = JSON.parse(message.data);

            setWebsocketMessages((prev) => [...prev, data]);
        };

        Axios.get('/api/chat_messages/?ordering=-created&page_size=20&chat_room__identifier=' + props.session.uuid).then((rsp) => {
            setMessages(rsp.data.results.reverse());
            setNextPage(rsp.data.next_page_number);
            setLoading(false);
        });

        return () => {
            webSocket.current.close();
        };
    }, [props.session.uuid]);

    const getNextPage = () => {
        Axios.get(`/api/chat_messages/?ordering=-created&page_size=20&chat_room__identifier=${props.session.uuid}&page=${nextPage}`).then((rsp) => {
            setMessages([...rsp.data.results.reverse(), ...messages]);
            setNextPage(rsp.data.next_page_number);
        });
    };

    const renderMsg = (player, msg, key, isSocket) => {
        const content = msg.content;
        const playerName = player?.user?.signature_name || player?.unconfirmed_name || '';
        const isLast = key === messages.length - 1;
        const isFirst = key === 0;
        const isMe = window.me === msg.user;

        let sameAsNext = false;
        let sameAsBefore = false;

        if (!isSocket) {
            sameAsNext = messages[key + 1]?.user === msg.user;
            sameAsBefore = messages[key - 1]?.user === msg.user;
        } else {
            sameAsNext = websocketMessages[key + 1]?.user === msg.user;
            sameAsBefore = websocketMessages[key - 1]?.user === msg.user;
        }

        if (isFirst && isSocket) {
            sameAsBefore = messages[messages.length - 1]?.user === msg.user;
        }

        if (isLast && !isSocket) {
            sameAsNext = websocketMessages[0]?.user === msg.user;
        }

        return (
            <React.Fragment key={key}>
                {!sameAsBefore && !isMe && <div className='playerNameSmall'>{playerName}</div>}
                <div className={`chatMessage ${isMe ? 'isMe' : ''} ${sameAsBefore && 'sameAsBefore'} ${sameAsNext && 'sameAsNext'}`}>
                    {!sameAsNext && (
                        <Tooltip title={playerName}>
                            <Avatar className='userPic' size='small' src={player?.user?.image}>
                                {playerName.charAt(0).toUpperCase()}
                            </Avatar>
                        </Tooltip>
                    )}

                    <div className='msg'>{content}</div>
                </div>
            </React.Fragment>
        );
    };

    const sendMessage = () => {
        try {
            webSocket.current.send(JSON.stringify({action: 'message', data: form.getFieldValue('message').trim()}));
            form.setFieldsValue({message: ''});
        } catch (err) {
            message.error('Úps, ekki tókst að senda skilaboðin');
        }
    };

    const allMessages = [...messages, ...websocketMessages];

    return (
        <>
            <div className={`chatMessageBox ${!!!allMessages.length ? 'empty' : ''}`}>
                {!!allMessages.length ? (
                    <div className='chatMessageContainer'>
                        {allMessages.map((msg, key) => {
                            const player = props.players.find((player) => player.user?.id === msg.user);

                            return renderMsg(player, msg, key, false);
                        })}
                    </div>
                ) : (
                    <div className='noMessages'>Spjallið er tómt</div>
                )}

                {nextPage && (
                    <Button
                        style={{color: 'white', background: 'transparent'}}
                        type='dashed'
                        loading={loading}
                        onClick={() => {
                            getNextPage();
                        }}
                    >
                        {!loading && <>Sækja Eldra</>}
                    </Button>
                )}
            </div>

            <Form form={form}>
                <Form.Item style={{margin: 0}} name='message'>
                    <Input.TextArea
                        className='messageInput'
                        onPressEnter={(e) => {
                            if (!form.getFieldValue('message').trim()) {
                                return;
                            }
                            e.persist();

                            const shiftKeyPressed = e.shiftKey;

                            if (!shiftKeyPressed) {
                                e.preventDefault();

                                sendMessage();
                            }
                        }}
                    ></Input.TextArea>
                </Form.Item>

                <Form.Item style={{textAlign: 'right', padding: 15, marginBottom: 0}}>
                    <Button
                        onClick={() => {
                            sendMessage();
                        }}
                        className='btn-success'
                        type='primary'
                    >
                        Senda
                    </Button>
                </Form.Item>
            </Form>

            {/* <Button
                className='emojiButton'
                icon={
                    <svg fill='#7a7a7a' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10'></path>
                        <path d='M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0'></path>
                    </svg>
                }
                shape='circle'
                onClick={() => {
                    setShowEmojiPicker(true);
                }}
            ></Button> */}

            {showEmojiPicker && (
                <Picker
                    onSelect={(emoji) => {
                        setShowEmojiPicker(false);
                        console.log(emoji);
                    }}
                    emoji=''
                    showPreview={false}
                    i18n={{
                        search: 'Leita',
                        categories: {
                            search: 'Leit',
                            recent: 'Nýlega valið',
                            people: 'Fólk',
                            nature: 'Náttúra',
                            foods: 'Matur',
                            activity: 'Athafnir',
                            places: 'Staðir',
                            objects: 'Hlutir',
                            symbols: 'Tákn',
                            flags: 'Fánar',
                            custom: 'Annað',
                        },
                        notfound: 'Fannst ekki',
                        skintext: 'Húðlitur',
                    }}
                />
            )}
        </>
    );
}
