import React, {Component} from 'react';
import {Form, Input, message, Button, Modal} from 'antd';
import Axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

export default class SendSessionReportModal extends Component {
    state = {};

    componentDidMount() {
        this.setState({
            session: this.props.session,
        });
    }

    render() {
        const onFinish = (values) => {
            const email = values.email;
            const subject = values.subject;

            const player_obj = {};

            Object.keys(values).forEach((key) => {
                if (key.includes('player_')) {
                    const uuid = key.split('_')[1];
                    player_obj[uuid] = player_obj[uuid] || {};

                    if (key.includes('_name')) player_obj[uuid].name = values[key];
                    if (key.includes('_phone')) player_obj[uuid].phone = values[key];
                }
            });

            const players = Object.keys(player_obj).map((key) => player_obj[key]);

            const body = `
            <div>
                <h2>Viðburðarskýrsla</h2>

                ${players
                    .map((player) => {
                        return `
                        <p>
                            <h4>${player.name}</h4>
                            ${player.phone ? '<h5>Sími: ' + player.phone + '</h5>' : ''}
                        </p>
                    `;
                    })
                    .join('')}
            </div>
            `;

            Axios.post('/send_session_report/', {subject, body, email})
                .then((rsp) => {
                    message.success('Skilaboðin hafa verið send!');
                    this.props.onCancel();
                })
                .catch((err) => {
                    message.error('Úps eitthvað fór úrskeiðis :(');
                });
        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };

        const location = this.props.session.location || this.props.session.event.location;

        return (
            <Modal visible={this.props.visible} onCancel={this.props.onCancel} title='Senda skýrslu' footer={false}>
                <Form name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed} layout='vertical'>
                    <Form.Item label='Hvert viltu senda skýrsluna (netfang)' name='email' rules={[{required: true, message: 'Þú verður að hafa netfang!'}]}>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item
                        label='Titill á tölvupósti'
                        name='subject'
                        rules={[{required: true, message: 'Þú verður að hafa titil!'}]}
                        initialValue={this.props.session.event.category.title + ' - ' + location.google_place_main_text + ' (' + moment(this.props.session.date).format('LL, LT') + ')'}
                    >
                        <Input size='large' />
                    </Form.Item>

                    {this.state.session?.confirmed?.map((player, i) => {
                        return (
                            <div key={i}>
                                <h6>Leikmaður {i + 1} - Nafn</h6>
                                <Form.Item name={'player_' + player.uuid + '_name'} initialValue={'Nafn: ' + (player.user?.signature_name || player.unconfirmed_name || '')}>
                                    <Input></Input>
                                </Form.Item>

                                <h6>Símanúmer</h6>
                                <Form.Item name={'player_' + player.uuid + '_phone'} initialValue={'Símanúmer: ' + (player?.user?.phone || player.unconfirmed_phone || '')}>
                                    <Input></Input>
                                </Form.Item>

                                <h6>Kennitala</h6>
                                <Form.Item name={'player_' + player.uuid + '_ssid'} initialValue={'Kennitala: ' + (player?.user?.ssid || player.unconfirmed_ssid || '')}>
                                    <Input></Input>
                                </Form.Item>
                                <br></br>
                            </div>
                        );
                    })}

                    <Form.Item>
                        <Button type='primary' block htmlType='submit'>
                            Senda
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
