import React, {Component} from 'react';
import {Layout} from 'antd';

import './Footer.less';

const {Footer: AntFooter} = Layout;

export default class Footer extends Component {
    render() {
        return <AntFooter className='text-center'>{this.props.children}</AntFooter>;
    }
}
