import React, {Component} from 'react';
import {Layout, Menu, Button, Avatar, Space} from 'antd';
import SVG from 'react-inlinesvg';

//REDUX
import {connect} from 'react-redux';
import {getUser} from '../../actions/user';

import './Header.less';

const {Header: AntHeader} = Layout;

export class Header extends Component {
    componentDidMount() {
        this.props.getUser();
    }

    render() {
        return (
            <AntHeader className='header'>
                <a className='logo' href='/'>
                    <SVG
                        id='header_logo'
                        style={{height: '64px', padding: 10}}
                        baseURL='/'
                        cacheRequests={true}
                        description='Vantar í bolta logo'
                        preProcessor={(code) => {
                            code = code.replace(/fill:.?#fff/g, 'fill:#7f00ff');
                            code = code.replace(/fill:.?#181818/g, 'fill:#fff');
                            return code;
                        }}
                        src={'/static/images/logo/logo_gray.svg'}
                        title='Vantar í bolta logo'
                        key='header_logo'
                        uniqueHash='header_logo'
                        uniquifyIDs={false}
                    />
                </a>
                <Menu className='main_menu' theme='dark' mode='horizontal'>
                    {/* <Menu.Item>
                        <Search className='searchbar' placeholder='input search loading default' loading />
                    </Menu.Item> */}

                    {this.props.user?.id && (
                        <Menu.SubMenu
                            style={{marginLeft: 'auto'}}
                            icon={
                                <Avatar src={this.props.user?.image?.image} style={{margin: '16px', backgroundColor: '#7f00ff'}}>
                                    {(this.props.user.signature_name || '').charAt(0).toUpperCase()}
                                </Avatar>
                            }
                        >
                            <Menu.Item>
                                <a href='/stillingar/'>Stillingar</a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href={'/minn_profill/'}>Prófíllinn minn</a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href='/utskra/'>Útskrá</a>
                            </Menu.Item>
                        </Menu.SubMenu>
                    )}

                    {!window.me && (
                        <Menu.Item style={{marginLeft: 'auto', backgroundColor: 'transparent', cursor: 'auto'}}>
                            <Space>
                                <Button className='btn-success' type='default'>
                                    <a style={{color: '#fff'}} href='/nyskra/'>
                                        Nýskráning
                                    </a>
                                </Button>

                                <Button type='dashed' style={{color: '#fff'}}>
                                    <a href='/innskra/'>Innskrá</a>
                                </Button>
                            </Space>
                        </Menu.Item>
                    )}
                </Menu>
            </AntHeader>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, {getUser})(Header);
