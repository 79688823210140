import React, {Component} from 'react';
import {Button, Modal, Form, Input, Radio, message} from 'antd';
import Axios from 'axios';

export default class ModalForm extends Component {
    formRef = React.createRef();

    state = {
        loading: true,
        player_type: this.props.player_type,
    };

    componentDidMount() {
        this.setState({
            loading: false,
        });
    }

    componentWillReceiveProps(props) {
        if (props.player_type) {
            this.setState({
                player_type: props.player_type,
            });
        }
    }

    formDidMount() {
        this.formRef.current.setFieldsValue({
            player_type: this.props.player_type,
        });
    }

    submitNewPlayer = (data) => {
        this.setState({
            loading: true,
        });

        const email = data.unconfirmed_email || '';
        let phone = data.unconfirmed_phone || '';
        const name = data.unconfirmed_name;

        if (phone) phone = encodeURIComponent('+354' + phone);

        Axios.get('/api/get_or_create_player/?email=' + email + '&phone=' + phone + '&name=' + name)
            .then((rsp) => {
                Axios.post('/api/add_remove_player_to_team_list/', {
                    player: rsp.data.uuid,
                    team: window.uuid,
                    list_name: data.player_type + 's',
                    action: 'add',
                })
                    .then((res) => {
                        this.props.addPlayerToList(rsp.data, data.player_type + 's');
                        this.setState({loading: false});

                        message.success('Jibbí, ' + data.unconfirmed_name + ' hefur verið bætt við liðið!');
                        this.formRef.current.setFieldsValue({
                            unconfirmed_name: null,
                            unconfirmed_email: null,
                            unconfirmed_phone: null,
                        });
                    })
                    .catch((error) => {
                        if (error.response?.status === 403) {
                            message.error('Þú hefur ekki leyfi til að framkvæma þessa aðgerð!');
                        } else {
                            this.setState({loading: false});
                            message.error('Úps eitthvað fór úrskeiðis, ekki tókst að bæta leikmanni við :(');
                        }
                    });
            })
            .catch((err) => {
                if (err.response?.status === 403) {
                    message.error('Þú hefur ekki leyfi til að framkvæma þessa aðgerð!');
                } else {
                    message.error('Úps eitthvað fór úrskeiðis, ekki tókst að bæta leikmanni við :(');
                    this.setState({loading: false});
                }
            });
    };

    render() {
        return (
            <Modal footer={null} visible={this.props.visible} title='Bæta einstakling í hópinn' onCancel={this.props.onCancel}>
                <p>Við mælum sterklega með því að þú fyllir út símanúmer og/eða netfang leikmanns, en það er þó ekki nauðsynlegt. </p>
                <p>Bæði svo hægt sé að senda áminningar varðandi mætingu og hinsvegar svo að viðkomandi get eignað sér leikmanninn þegar hann eða hún býr til aðgang.</p>
                <br />
                {this.state.player_type && (
                    <Form initialValues={{player_type: this.state.player_type}} ref={this.formRef} onFinish={this.submitNewPlayer.bind(this)} layout='vertical' name='basic'>
                        <Form.Item
                            label='Nafn'
                            name='unconfirmed_name'
                            rules={[
                                {
                                    required: true,
                                    message: 'Nafn er ekki valkvætt!',
                                },
                            ]}
                        >
                            <Input size='large' autoComplete='new-password' />
                        </Form.Item>

                        <Form.Item label='Netfang' name='unconfirmed_email'>
                            <Input size='large' autoComplete='new-password' />
                        </Form.Item>

                        <Form.Item
                            label='Símanúmer'
                            name='unconfirmed_phone'
                            rules={[
                                {
                                    validator: (rule, value) => {
                                        return !value || value.length === 7 ? Promise.resolve() : Promise.reject('Símanúmerið þarf að vera 7 stafir');
                                    },
                                },
                            ]}
                        >
                            <Input size='large' autoComplete='new-password' />
                        </Form.Item>

                        <Form.Item name='player_type'>
                            <Radio.Group valuePropName='player_type' defaultValue={this.state.player_type} optionType='button' buttonStyle='solid'>
                                <Radio.Button label='Aðal hópur' value='player'>
                                    Aðal hópur
                                </Radio.Button>

                                <Radio.Button label='Varamannabekkur' value='substitute'>
                                    Varamannabekkurinn
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item>
                            <Button block size='large' loading={this.state.loading} type='primary' htmlType='submit'>
                                Vista
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        );
    }
}
