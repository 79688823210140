import React, {Component} from 'react';
import axios from 'axios';
import './PlayerDetails.less';
import moment from 'moment';

import {Card, Col, Row, Layout, List, Avatar, Space, Button, Progress} from 'antd';
import {PhoneTwoTone} from '@ant-design/icons';

import TeamShirt from '../../components/TeamShirt/TeamShirt';

const {Content} = Layout;

export default class PlayerDetails extends Component {
    state = {};

    componentDidMount() {
        axios('/get_player_stats/?uuid=' + window.uuid).then((rsp) => {
            this.setState({
                loading: false,
                player: rsp.data.player_details,
                stats: rsp.data.stats,
            });

            axios('/api/team/?event__team__players=' + rsp.data.player_details.id).then((res) => {
                this.setState({
                    player_teams: res.data.results,
                });

                res.data.results.forEach((team) => {
                    axios('/get_team_attendance/?uuid=' + team.uuid).then((_rsp) => {
                        console.log(_rsp);
                        this.setState({
                            player_teams: [
                                ...this.state.player_teams.filter((_team) => _team.uuid !== team.uuid),
                                {
                                    ...team,
                                    attendance: {
                                        total_sessions: _rsp.data.total_sessions,
                                        player: _rsp.data.player_stats.find((_player) => _player.id === rsp.id),
                                    },
                                },
                            ],
                        });
                    });
                });
            });

            axios('/api/team/?event__team__substitutes=' + rsp.data.player_details.id).then((res) => {
                this.setState({
                    substitute_teams: res.data.results,
                });

                res.data.results.forEach((team) => {
                    axios('/get_team_attendance/?uuid=' + team.uuid).then((_rsp) => {
                        this.setState({
                            substitute_teams: [
                                ...this.state.substitute_teams.filter((_team) => _team.uuid !== team.uuid),
                                {...team, attendance: {total_sessions: _rsp.data.total_sessions, player: _rsp.data.player_stats.find((_player) => _player.id === rsp.id)}},
                            ],
                        });
                    });
                });
            });
        });
    }

    render() {
        console.log(this.state);
        return (
            <>
                <Layout>
                    <Content>
                        <Row type='flex' gutter={[10, 10]} style={{margin: 10, justifyContent: 'center'}}>
                            <Col xs={24} sm={18} md={16} lg={12}>
                                <Space direction='vertical' style={{display: 'flex'}}>
                                    <Card type='inner' title='Leikmaður' extra={'Stofnaður: ' + moment(this.state.player?.created).format('LL')} loading={this.state.loading}>
                                        <div className='text-center'>
                                            <Avatar size='large' style={{width: 100, height: 100, lineHeight: '100px', fontSize: '50px'}} src={this.state.player?.user?.image?.image}>
                                                {(this.state.player?.user?.signature_name || this.state.player?.unconfirmed_name || '').charAt(0).toUpperCase()}
                                            </Avatar>
                                            <h2>{this.state.player?.user?.signature_name || this.state.player?.unconfirmed_name || ''}</h2>
                                            {(this.state.player?.unconfirmed_phone || this.state.player?.user?.phone) && (
                                                <h3>
                                                    <Button>
                                                        <a href={'tel:' + this.state.player?.user?.phone || this.state.player?.unconfirmed_phone}>
                                                            <PhoneTwoTone twoToneColor='gray' /> {(this.state.player?.user?.phone || this.state.player?.unconfirmed_phone).split('+354')}
                                                        </a>
                                                    </Button>
                                                </h3>
                                            )}
                                        </div>
                                    </Card>

                                    <Card type='inner' title='Lið'>
                                        <List
                                            itemLayout='horizontal'
                                            dataSource={this.state.player_teams || []}
                                            locale={{emptyText: 'Engin lið fundust'}}
                                            renderItem={(team) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <div style={{position: 'relative'}}>
                                                                <TeamShirt
                                                                    style={{width: '35px'}}
                                                                    className='shirt'
                                                                    kit_color={team.main_kit_color}
                                                                    kit_secondary_color={team.main_kit_secondary_color}
                                                                    kit_template={team.main_kit_template}
                                                                ></TeamShirt>
                                                            </div>
                                                        }
                                                        title={
                                                            <>
                                                                <div>
                                                                    <a href={'/lid/' + team.uuid + '/'}>{team.name}</a>
                                                                </div>

                                                                {team.attendance?.total_sessions && team.attendance?.player.count && (
                                                                    <div>
                                                                        Mæting ({team.attendance?.player.count} af {team.attendance?.total_sessions})
                                                                        <Progress
                                                                            strokeColor={
                                                                                ((team.attendance?.player.count / team.attendance?.total_sessions) * 100 || 0).toFixed(1) < 50
                                                                                    ? '#ca3a3a'
                                                                                    : ((team.attendance?.player.count / team.attendance?.total_sessions) * 100 || 0).toFixed(1)
                                                                                    ? '#ffe504'
                                                                                    : '#3aca8b'
                                                                            }
                                                                            percent={((team.attendance?.player.count / team.attendance?.total_sessions) * 100 || 0).toFixed(1)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Card>

                                    <Card type='inner' title='Lið (sem varamaður)'>
                                        <List
                                            itemLayout='horizontal'
                                            dataSource={this.state.substitute_teams || []}
                                            locale={{emptyText: 'Engin lið fundust'}}
                                            renderItem={(team) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <div style={{position: 'relative'}}>
                                                                <TeamShirt
                                                                    style={{width: '35px'}}
                                                                    className='shirt'
                                                                    kit_color={team.away_kit_color}
                                                                    kit_secondary_color={team.away_kit_secondary_color}
                                                                    kit_template={team.away_kit_template}
                                                                ></TeamShirt>
                                                            </div>
                                                        }
                                                        title={
                                                            <>
                                                                <div>
                                                                    <a href={'/lid/' + team.uuid + '/'}>{team.name}</a>
                                                                </div>

                                                                {team.attendance?.total_sessions && team.attendance?.player.count && (
                                                                    <div>
                                                                        Mæting ({team.attendance?.player.count} af {team.attendance?.total_sessions})
                                                                        <Progress
                                                                            strokeColor={
                                                                                ((team.attendance?.player.count / team.attendance?.total_sessions) * 100 || 0).toFixed(1) < 50
                                                                                    ? '#ca3a3a'
                                                                                    : ((team.attendance?.player.count / team.attendance?.total_sessions) * 100 || 0).toFixed(1)
                                                                                    ? '#ffe504'
                                                                                    : '#3aca8b'
                                                                            }
                                                                            percent={((team.attendance?.player.count / team.attendance?.total_sessions) * 100 || 0).toFixed(1)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Space>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </>
        );
    }
}
