import React, {Component} from 'react';
import SVG from 'react-inlinesvg';

export default class TeamShirt extends Component {
    render() {
        return (
            <>
                {this.props.kit_template && (
                    <div style={{...this.props.style, position: 'relative', display: 'inline-block'}}>
                        <SVG
                            style={{width: '100%'}}
                            className={this.props.className}
                            baseURL='/'
                            cacheRequests={true}
                            description='Buningur'
                            onError={(error) => console.log(error.message)}
                            preProcessor={(code) => {
                                if (this.props.kit_color) code = code.replace(/fill:.?#BDBDBD/g, 'fill:' + this.props.kit_color);
                                if (this.props.kit_secondary_color) code = code.replace(/fill:.?#636363/g, 'fill:' + this.props.kit_secondary_color);
                                return code;
                            }}
                            src={'/static/images/shirt_templates/' + this.props.kit_template + '.svg'}
                            title='Buningur'
                            key={this.props.kit_color + this.props.kit_secondary_color + this.props.kit_template}
                            uniqueHash={this.props.kit_color + this.props.kit_secondary_color + this.props.kit_template}
                            uniquifyIDs={false}
                        />
                        <img style={{position: 'absolute', left: 0, opacity: 0.5, width: '100%'}} src='/static/images/shirt_templates/overlay.png' alt='svg shirt overlay' />
                    </div>
                )}
            </>
        );
    }
}
