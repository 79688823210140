import React, {Component} from 'react';
import axios from 'axios';
import './Profile.less';

import {Card, Col, Row, Layout, Form, Input, Button, message} from 'antd';

import UploadImage from '../../components/UploadImage/UploadImage';
import {FacebookProvider, SendToMessenger} from 'react-facebook';

const {Content} = Layout;

export default class Profile extends Component {
    formRef = React.createRef();

    state = {
        loading: true,
        init_image_check: false,
        user: {},
        imagFileList: null,
    };

    componentDidMount() {
        axios('/api/user/' + window.me + '/').then((rsp) => {
            this.setState({
                loading: false,
                user: rsp.data,
                imageFileList: rsp.data.image
                    ? [
                          {
                              url: rsp.data.image.image,
                              status: 'done',
                              name: 'image.png',
                              uid: rsp.data.image.id,
                          },
                      ]
                    : [],
            });

            this.formRef.current.setFieldsValue(rsp.data);
        });
    }

    onFinish(data) {
        data.image = data.image?.id || data.image;
        if (data.phone && data.phone.length === 7) data.phone = '+354' + data.phone;
        axios
            .patch('/api/user/' + window.me + '/update/', data)
            .then((rsp) => {
                message.success('Tókst! Prófíllinn þinn hefur verið uppfærður');
            })
            .catch((err) => {
                if (err.response?.data) {
                    const key = Object.keys(err.response.data)[0];

                    message.error(key + ': ' + err.response.data[key]);
                } else {
                    message.error('Úps, eitthvað fór úrskeiðis!');
                }
            });
    }

    remove_messenger_id() {
        axios
            .patch('/api/user/' + window.me + '/update/', {fb_recipient_id: null})
            .then((rsp) => {
                message.success('Tókst! Prófíllinn þinn hefur verið uppfærður');
                const user = this.state.user;
                user.fb_recipient_id = null;
                this.setState({user: user});
            })
            .catch((err) => {
                message.error('Úps, eitthvað fór úrskeiðis. Villa hefur verið send á tæknistjóra!');
            });
    }

    onFinishFailed(errorInfo) {
        message.error('Úps, lagaðu formið áður en þú uppfærir :)');
    }

    onImageUpload(image_id) {
        this.formRef.current.setFieldsValue({
            image: parseInt(image_id),
        });
    }

    render() {
        return (
            <>
                <Layout>
                    <Content>
                        <Row type='flex' gutter={[10, 10]} style={{margin: 10}}>
                            <Col xs={24} sm={18} md={16} lg={12} xl={10} xxl={8}>
                                <Card title='Stillingar' loading={this.state.loading}>
                                    <Form ref={this.formRef} layout='vertical' name='basic' onFinish={this.onFinish.bind(this)} onFinishFailed={this.onFinishFailed.bind(this)}>
                                        <Form.Item label='Netfang' name='email' rules={[{required: true, message: 'Þú verður að hafa netfang!'}]}>
                                            <Input autoComplete='new-password' />
                                        </Form.Item>

                                        <Form.Item label='Símanúmer' name='phone'>
                                            <Input autoComplete='new-password' />
                                        </Form.Item>

                                        <Form.Item label='Nafn/gælunafn (sýnilegt öllum)' name='signature_name'>
                                            <Input autoComplete='new-password' />
                                        </Form.Item>

                                        {/* <Form.Item name='remember' valuePropName='checked'>
                                            <Checkbox>Remember me</Checkbox>
                                        </Form.Item> */}

                                        <Form.Item name='image' valuePropName='fileList' getValueFromEvent={UploadImage.onChange}>
                                            <UploadImage defaultFileList={this.state.imageFileList} onSuccess={this.onImageUpload.bind(this)}></UploadImage>
                                        </Form.Item>

                                        <Form.Item>
                                            <Button className='btn-success' block size='large' loading={this.state.loading} type='primary' htmlType='submit'>
                                                Vista
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </Col>

                            <Col xs={24} sm={18} md={16} lg={8} xl={6} xxl={5}>
                                <Card title='Fáðu tilkynningar á messenger' type='inner' loading={this.state.loading}>
                                    {!this.state.user.fb_recipient_id ? (
                                        <>
                                            <p>Þú færð einkaskilaboð á Facebook Messenger með upplýsingum um viðburðina þína.</p>
                                            <p>
                                                <strong>Dæmi:</strong> Það er mæting í dag kl 20, smelltu hér til að skrá þig: <a href='/'>"https://vantaribolta.is/timi/123/"</a>
                                            </p>
                                            <br />
                                            <div className='text-left' style={{width: 178, margin: 'auto'}}>
                                                <FacebookProvider appId='238324500800347'>
                                                    <SendToMessenger messengerAppId='238324500800347' pageId='104971504676510' dataRef={window.me} size='xlarge' color='white' />
                                                </FacebookProvider>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h3>Þú ert búin/n að virkja þennan fítus, vel gert!</h3>

                                            <Button onClick={this.remove_messenger_id.bind(this)} danger type='dashed'>
                                                Hætta að fá Facebook áminningar
                                            </Button>
                                        </>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </>
        );
    }
}
