import React, {Component} from 'react';
import './HeroSection.less';

export class HeroSection extends Component {
    componentDidMount() {
        this.setState({
            backgroundImage: this.props.backgroundImage,
        });
    }

    render() {
        let {backgroundImage, heightSubtract, vheight, overlay} = this.props;
        backgroundImage = backgroundImage ? 'url(' + backgroundImage + ')' : '';
        vheight = vheight || 100;
        const height = heightSubtract ? 'calc(' + vheight + 'vh - ' + heightSubtract + ')' : vheight + 'vh';
        const className = overlay ? 'HeroSection hero_overlay' : 'HeroSection';

        return (
            <>
                <section
                    id={this.props.id}
                    className={className}
                    style={{
                        backgroundImage: backgroundImage,
                        minHeight: height,
                        backgroundColor: this.props.backgroundColor || '',
                    }}
                >
                    <div className='innerContainer'>
                        <div className='mainContent'>{this.props.children}</div>
                    </div>
                </section>
            </>
        );
    }
}

export default HeroSection;
