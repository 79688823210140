import React, {Component} from 'react';
import {Layout, Card, Col, Row, Space, Button, List, Avatar, Modal, message, Dropdown, Menu, Progress} from 'antd';

// MODALS
import ApplyToJoinList from './modals/ApplyToJoinList';
import ModalForm from '../../components/ModalForm/ModalForm';

// COMPONENTS
import Declensions from '../../components/Declensions/Declensions';
import TeamShirt from '../../components/TeamShirt/TeamShirt';
import EventCalendar from '../../components/EventCalendar/EventCalendar';

// LIBRARIES
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/is';
import ReactTimeAgo from 'react-time-ago';
import axios from 'axios';

// LESS
import './Team.less';

// VARIABLES
const {Content} = Layout;

export default class Team extends Component {
    state = {
        substitutes: [],
        calendar_sessions: [],
        loading: true,
        modal_loading: false,
        team: {},
        deletePlayerModalVisible: false,
        calendar_loading: true,
        logged_in_player: null,
    };

    componentDidMount() {
        axios.get('/api/team/' + window.uuid + '/').then((rsp) => {
            this.setState({
                team: rsp.data,
                loading: false,
            });
        });

        axios('/get_team_attendance/?uuid=' + window.uuid).then((rsp) => {
            this.setState({
                stats: rsp.data.player_stats,
                stat_session_count: rsp.data.total_sessions,
            });
        });

        axios.get('/api/session/?event__team__uuid=' + window.uuid + '&date__gte=' + moment().toISOString() + '&ordering=date&page_size=1').then((rsp) => {
            this.setState({
                next_session: rsp.data.results ? rsp.data.results[0] : {},
            });
        });

        axios.get('/api/event/?team__uuid=' + window.uuid + '&ordering=-created&frequency_type=WEEKLY').then((rsp) => {
            this.setState({
                events: rsp.data.results,
            });
        });

        if (window.me) {
            axios.get('/api/get_or_create_player/?user=' + window.me).then((rsp) => {
                this.setState({
                    logged_in_player: rsp.data,
                });
            });
        }
    }

    addPlayerToList(new_player, list_name, also_make_request) {
        let team = {...this.state.team};
        team[list_name] = [...this.state.team[list_name], new_player];

        if (also_make_request) {
            axios
                .post('/api/add_remove_player_to_team_list/', {
                    player: new_player.uuid,
                    team: window.uuid,
                    list_name: list_name,
                    action: 'add',
                })
                .then((rsp) => {
                    if (list_name === 'players') {
                        team.substitutes = team.substitutes.filter((player) => player.id !== new_player.id);
                        team.player_applications = team.player_applications.filter((player) => player.id !== new_player.id);
                        team.substitute_applications = team.substitute_applications.filter((player) => player.id !== new_player.id);
                    }

                    if (list_name === 'substitutes') {
                        team.players = team.players.filter((player) => player.id !== new_player.id);
                        team.player_applications = team.player_applications.filter((player) => player.id !== new_player.id);
                        team.substitute_applications = team.substitute_applications.filter((player) => player.id !== new_player.id);
                    }

                    this.setState({team});

                    message.success('Jibbí, tókst');
                })
                .catch((err) => {
                    if (err.response?.status === 403) {
                        message.error(err.response?.data?.message || 'Þú hefur ekki leyfi til að framkvæma þessa aðgerð!');
                    } else {
                        message.error('Úps, eitthvað fór úrskeiðis :(');
                    }
                });
        } else {
            this.setState({team});
        }
    }

    removePlayerFromList(player, list_name, skip_request) {
        let team = {...this.state.team};
        team[list_name] = [...this.state.team[list_name].filter((pl) => player.id !== pl.id)];

        if (!skip_request) {
            axios
                .post('/api/add_remove_player_to_team_list/', {
                    player: player.uuid,
                    team: window.uuid,
                    list_name: list_name,
                    action: 'remove',
                })
                .then((rsp) => {
                    this.setState({team});
                    message.success('Tókst!');
                })
                .catch((err) => {
                    if (err.response?.status === 403) {
                        message.error(err.response?.data?.message || 'Þú hefur ekki leyfi til að framkvæma þessa aðgerð!');
                    } else {
                        message.error('Úps, eitthvað fór úrskeiðis :(');
                    }
                });
        } else {
            this.setState({team});
        }
    }

    deletePlayer() {
        const player = this.state.deletePlayer;
        const list_name = this.state.removeList;
        this.removePlayerFromList(player, list_name);
    }

    isCaptain = (player) => {
        return this.state.team?.captains.filter((pl) => pl.id === player.id).length;
    };

    hidePlayerModal = () => {
        this.setState({playerModalVisible: false, modal_player_type: null});
    };

    is_in_team = (listname) => {
        listname = listname || 'players';
        const in_team = this.state.team[listname]?.filter((player) => player.user?.id === window.me) || [];

        return !!in_team.length;
    };

    render() {
        return (
            <>
                <Layout id='team'>
                    <Content>
                        <Row type='flex' className='main_team_row' gutter={[10, 10]}>
                            <Col className='text-center' xs={24}>
                                <h1 style={{color: '#fff'}}>{this.state.team?.name}</h1>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Space style={{display: 'flex'}} direction='vertical'>
                                    <Card
                                        title='Búningar'
                                        loading={this.state.loading}
                                        type='inner'
                                        extra={
                                            this.is_in_team() && (
                                                <Button type='dashed' block>
                                                    <a href={'/buningar/' + window.uuid + '/'}>Breyta</a>
                                                </Button>
                                            )
                                        }
                                    >
                                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px', textAlign: 'center'}}>
                                            <div>
                                                <TeamShirt
                                                    style={{maxWidth: 140, margin: 'auto', width: '100%'}}
                                                    className='shirt'
                                                    kit_color={this.state.team.main_kit_color}
                                                    kit_secondary_color={this.state.team.main_kit_secondary_color}
                                                    kit_template={this.state.team.main_kit_template}
                                                ></TeamShirt>
                                                <h5>Aðalbúningur</h5>
                                            </div>

                                            <div style={{textAlign: 'center'}}>
                                                <TeamShirt
                                                    style={{maxWidth: 140, margin: 'auto'}}
                                                    className='shirt'
                                                    kit_color={this.state.team.away_kit_color}
                                                    kit_secondary_color={this.state.team.away_kit_secondary_color}
                                                    kit_template={this.state.team.away_kit_template}
                                                ></TeamShirt>
                                                <h5>Varabúningur</h5>
                                            </div>
                                        </div>
                                    </Card>

                                    <Card
                                        loading={this.state.loading}
                                        title='Viðburðir'
                                        className='event_card text-left'
                                        type='inner'
                                        extra={
                                            this.is_in_team() && (
                                                <Button type='dashed'>
                                                    <a href={'/stofna_vidburd/' + window.uuid + '/'}>+ Bæta við</a>
                                                </Button>
                                            )
                                        }
                                    >
                                        <div className='text-center'>
                                            <Space direction='vertical'>
                                                {this.state.next_session ? (
                                                    <>
                                                        <div>
                                                            <h4>
                                                                Næsti viðburður er <ReactTimeAgo locale='is' date={new Date(this.state.next_session?.date)} timeStyle='time' />
                                                            </h4>
                                                            <h5>
                                                                <Moment style={{textTransform: 'capitalize'}} locale='is' format='dddd'>
                                                                    {this.state.next_session?.date}
                                                                </Moment>
                                                                <Moment style={{textTransform: 'capitalize'}} locale='is' format=', DD. MMMM'>
                                                                    {this.state.next_session?.date}
                                                                </Moment>{' '}
                                                                {this.state.next_session.event.time_of_day &&
                                                                    this.state.next_session.event.time_of_day
                                                                        .split(':')
                                                                        .filter((val, key) => key < 2)
                                                                        .join(':')}
                                                            </h5>

                                                            <Button className='btn-success' type='primary'>
                                                                <a href={'/timi/' + this.state.next_session.uuid + '/'}>Skoða viðburð</a>
                                                            </Button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div>
                                                        <p>Það eru engir viðburðir bókaðir í framtíðinni.</p>
                                                        <p>Viðburðir geta verið reglubundnir, dæmi: á hverjum miðvikudegi kl 19:50.</p>

                                                        {this.is_in_team() && (
                                                            <Button className='mt20 btn-success' block type='primary'>
                                                                <a href={'/stofna_vidburd/' + window.uuid + '/'}>+ Búa til viðburð</a>
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}

                                                <hr />

                                                <h2>Viðburðadagatal</h2>

                                                <EventCalendar url={'/api/session/?event__team__uuid=' + window.uuid + '&page_size=100'}></EventCalendar>
                                            </Space>
                                        </div>
                                    </Card>

                                    <h2 className='text-center' style={{color: '#fff', marginBottom: 0, marginTop: 10}}>
                                        Reglubundnir viðburðir
                                    </h2>
                                    {this.state.events?.map((item, key) => {
                                        return (
                                            <Card
                                                key={key}
                                                className='text-left'
                                                type='inner'
                                                title={item.location?.title}
                                                extra={
                                                    this.is_in_team() && (
                                                        <Dropdown.Button
                                                            overlay={
                                                                <Menu>
                                                                    <Menu.Item
                                                                        onClick={() => {
                                                                            this.setState({delete_event_modal: true, delete_event: item});
                                                                        }}
                                                                        danger
                                                                    >
                                                                        Eyða
                                                                    </Menu.Item>
                                                                </Menu>
                                                            }
                                                        >
                                                            <a href={'/aminningar/' + item.uuid + '/'}>Stilla áminningar</a>
                                                        </Dropdown.Button>
                                                    )
                                                }
                                            >
                                                <div className='text-center'>
                                                    {item.frequency_type === 'YEARLY' && (
                                                        <div>
                                                            {item.frequency_count === 1 && <div>Einu sinni á ári</div>}
                                                            {item.frequency_count > 1 && <div>{item.frequency_count} hvert ár</div>}
                                                        </div>
                                                    )}

                                                    {item.frequency_type === 'MONTHLY' && (
                                                        <div>
                                                            {item.frequency_count === 1 && <div>Mánaðarlega</div>}
                                                            {item.frequency_count > 1 && <div>{item.frequency_count} hvern mánuð</div>}
                                                            <div>{item.day_of_month}. í hverjum mánuði</div>
                                                        </div>
                                                    )}

                                                    {item.frequency_type === 'WEEKLY' && (
                                                        <div>
                                                            {item.frequency_count === 1 && <span>Vikulega </span>}
                                                            {item.frequency_count > 1 && <span>{item.frequency_count} hverja viku </span>}
                                                            <span>á </span>

                                                            <Declensions style={{display: 'inline-block'}} declenasion='thagufall' plural={true}>
                                                                {item.day_of_week}
                                                            </Declensions>
                                                        </div>
                                                    )}

                                                    {item.frequency_type === 'DAILY' && (
                                                        <div>
                                                            {item.frequency_count === 1 && <div>Daglega</div>}
                                                            {item.frequency_count > 1 && <div>{item.frequency_count} hvern dag</div>}
                                                        </div>
                                                    )}
                                                    <h3 style={{margin: 0}}>
                                                        {item.time_of_day &&
                                                            item.time_of_day
                                                                .split(':')
                                                                .filter((val, key) => key < 2)
                                                                .join(':')}
                                                    </h3>
                                                </div>
                                            </Card>
                                        );
                                    })}

                                    {!this.state.events?.length && (
                                        <div className='text-center' style={{color: '#fff'}}>
                                            Engin reglubundinn viðburður fannst
                                        </div>
                                    )}
                                </Space>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={9}>
                                <Space style={{display: 'flex'}} direction='vertical'>
                                    <Card
                                        type='inner'
                                        loading={this.state.loading}
                                        title={
                                            <div>
                                                <div>
                                                    {'Leikmannalisti'} ({this.state.team?.players?.length}){' '}
                                                </div>
                                                <div className='bold captain_color' style={{fontSize: 11}}>
                                                    * Fyrirliði
                                                </div>
                                            </div>
                                        }
                                        extra={
                                            this.is_in_team() ? (
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            playerModalVisible: true,
                                                            modal_player_type: 'player',
                                                        });
                                                    }}
                                                    type='dashed'
                                                >
                                                    + Bæta við
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => {
                                                        this.setState({applyToJoinList: 'player_applications', applyToJoinType: 'player'});
                                                    }}
                                                    type='dashed'
                                                >
                                                    + Sækja um
                                                </Button>
                                            )
                                        }
                                    >
                                        {this.state.team?.players?.length ? (
                                            <List
                                                className='text-left'
                                                itemLayout='horizontal'
                                                dataSource={this.state.team.players}
                                                renderItem={(player) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={
                                                                <div style={{position: 'relative'}}>
                                                                    <TeamShirt
                                                                        style={{width: '35px'}}
                                                                        className='shirt'
                                                                        kit_color={this.state.team.main_kit_color}
                                                                        kit_secondary_color={this.state.team.main_kit_secondary_color}
                                                                        kit_template={this.state.team.main_kit_template}
                                                                    ></TeamShirt>
                                                                    <Avatar style={{position: 'absolute', left: 0, bottom: 0}} size='small' src={player.user?.image?.image}>
                                                                        {(player.user?.signature_name || player.unconfirmed_name || '').charAt(0).toUpperCase()}
                                                                    </Avatar>
                                                                </div>
                                                            }
                                                            title={
                                                                <a className={this.isCaptain(player) ? 'captain_color bold' : 'capitalize'} href={'/leikmadur/' + player.uuid + '/'}>
                                                                    {player.user?.signature_name || player.unconfirmed_name}
                                                                </a>
                                                            }
                                                            description={
                                                                <>
                                                                    {this.state.stats &&
                                                                        this.state.stats
                                                                            .filter((pl) => {
                                                                                return pl.confirmed__id === player.id;
                                                                            })
                                                                            .map((_player, key) => {
                                                                                const percent = ((_player.count / this.state.stat_session_count) * 100 || 0).toFixed(1);
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <div>
                                                                                            Mæting ({_player.count} af {this.state.stat_session_count})
                                                                                        </div>
                                                                                        <Progress strokeColor={percent < 50 ? '#ca3a3a' : percent < 85 ? '#ffe504' : '#3aca8b'} percent={percent} />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                </>
                                                            }
                                                        />
                                                        {this.is_in_team() && (
                                                            <Dropdown.Button
                                                                onClick={() => {
                                                                    this.setState({deletePlayerModalVisible: true, deletePlayer: player, removeList: 'players'});
                                                                }}
                                                                overlay={
                                                                    <Menu>
                                                                        <Menu.Item
                                                                            onClick={() => {
                                                                                this.addPlayerToList(player, 'substitutes', true);
                                                                            }}
                                                                        >
                                                                            Færa á varamannabekkinn
                                                                        </Menu.Item>
                                                                        <Menu.Item
                                                                            onClick={() => {
                                                                                this.addPlayerToList(player, 'captains', true);
                                                                            }}
                                                                        >
                                                                            Gera að fyrirliða
                                                                        </Menu.Item>
                                                                    </Menu>
                                                                }
                                                            >
                                                                Eyða
                                                            </Dropdown.Button>
                                                        )}
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <div>Leikmannalistinn er tómur!</div>
                                        )}
                                    </Card>

                                    <Card
                                        loading={this.state.loading}
                                        title={'Varamannabekkurinn (' + (this.state.team?.substitutes?.length || 0) + ')'}
                                        type='inner'
                                        extra={
                                            this.is_in_team() ? (
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            playerModalVisible: true,
                                                            modal_player_type: 'substitute',
                                                        });
                                                    }}
                                                    type='dashed'
                                                >
                                                    + Bæta við
                                                </Button>
                                            ) : (
                                                <>
                                                    {!this.is_in_team('substitutes') && (
                                                        <Button
                                                            onClick={() => {
                                                                this.setState({applyToJoinList: 'substitute_applications', applyToJoinType: 'substitute'});
                                                            }}
                                                            type='dashed'
                                                        >
                                                            + Sækja um
                                                        </Button>
                                                    )}
                                                </>
                                            )
                                        }
                                    >
                                        <p>Þeir sem eru á varamannabekknum geta skráð sig EF einhver úr aðal hópnum mætir ekki.</p>
                                        {this.state.team?.substitutes?.length ? (
                                            <List
                                                className='text-left'
                                                itemLayout='horizontal'
                                                dataSource={this.state.team.substitutes}
                                                renderItem={(player) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={
                                                                <div style={{position: 'relative'}}>
                                                                    <TeamShirt
                                                                        style={{width: '35px'}}
                                                                        className='shirt'
                                                                        kit_color={this.state.team.away_kit_color}
                                                                        kit_secondary_color={this.state.team.away_kit_secondary_color}
                                                                        kit_template={this.state.team.away_kit_template}
                                                                    ></TeamShirt>
                                                                    <Avatar style={{position: 'absolute', left: 0, bottom: 0}} size='small' src={player.user?.image?.image}>
                                                                        {(player.user?.signature_name || player.unconfirmed_name || '').charAt(0).toUpperCase()}
                                                                    </Avatar>
                                                                </div>
                                                            }
                                                            title={
                                                                <a className='capitalize' href={'/leikmadur/' + player.uuid + '/'}>
                                                                    {player.user?.signature_name || player.unconfirmed_name}
                                                                </a>
                                                            }
                                                            description={
                                                                <>
                                                                    {this.state.stats &&
                                                                        this.state.stats
                                                                            .filter((pl) => {
                                                                                return pl.confirmed__id === player.id;
                                                                            })
                                                                            .map((_player, key) => {
                                                                                const percent = ((_player.count / this.state.stat_session_count) * 100 || 0).toFixed(1);
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <div>
                                                                                            Mæting ({_player.count} af {this.state.stat_session_count})
                                                                                        </div>
                                                                                        <Progress strokeColor={'#e100ff'} percent={percent} />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                </>
                                                            }
                                                        />
                                                        {this.is_in_team() && (
                                                            <Space>
                                                                <Dropdown.Button
                                                                    onClick={() => {
                                                                        this.setState({deletePlayerModalVisible: true, deletePlayer: player, removeList: 'substitutes'});
                                                                    }}
                                                                    overlay={
                                                                        <Menu>
                                                                            <Menu.Item
                                                                                onClick={() => {
                                                                                    this.addPlayerToList(player, 'players', true);
                                                                                }}
                                                                            >
                                                                                Færa í aðallið
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                >
                                                                    Eyða
                                                                </Dropdown.Button>
                                                            </Space>
                                                        )}
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <div>Varamannabekkurinn er tómur!</div>
                                        )}
                                    </Card>

                                    {(!!this.state.team?.substitute_applications?.length || !!this.state.team?.player_applications?.length) && (
                                        <Card
                                            loading={this.state.loading}
                                            title={'Umsóknir (' + (parseInt(this.state.team?.substitute_applications?.length || 0) + parseInt(this.state.team?.player_applications?.length) || 0) + ')'}
                                            type='inner'
                                        >
                                            {!!this.state.team?.player_applications?.length && (
                                                <>
                                                    <h3>Umsóknir í aðallið</h3>
                                                    <List
                                                        className='text-left'
                                                        itemLayout='horizontal'
                                                        dataSource={this.state.team.player_applications}
                                                        renderItem={(player) => (
                                                            <List.Item
                                                                extra={
                                                                    <>
                                                                        {this.is_in_team() && (
                                                                            <Space>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        this.addPlayerToList(player, 'players', true);
                                                                                    }}
                                                                                    className='btn-success'
                                                                                    type='primary'
                                                                                >
                                                                                    Samþykkja
                                                                                </Button>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        this.removePlayerFromList(player, 'player_applications');
                                                                                    }}
                                                                                    type='primary'
                                                                                    danger
                                                                                >
                                                                                    Hafna
                                                                                </Button>
                                                                            </Space>
                                                                        )}
                                                                    </>
                                                                }
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <div style={{position: 'relative'}}>
                                                                            <TeamShirt
                                                                                style={{width: '35px'}}
                                                                                className='shirt'
                                                                                kit_color={this.state.team.main_kit_color}
                                                                                kit_secondary_color={this.state.team.main_kit_secondary_color}
                                                                                kit_template={this.state.team.main_kit_template}
                                                                            ></TeamShirt>
                                                                            <Avatar style={{position: 'absolute', left: 0, bottom: 0}} size='small' src={player.user?.image?.image}>
                                                                                {(player.user?.signature_name || player.unconfirmed_name || '').charAt(0).toUpperCase()}
                                                                            </Avatar>
                                                                        </div>
                                                                    }
                                                                    title={
                                                                        <a className='capitalize' href={'/leikmadur/' + player.uuid + '/'}>
                                                                            {player.user?.signature_name || player.unconfirmed_name}
                                                                        </a>
                                                                    }
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                </>
                                            )}

                                            {!!this.state.team?.substitute_applications?.length && (
                                                <>
                                                    <h3>Umsóknir í varalið</h3>
                                                    <List
                                                        className='text-left'
                                                        itemLayout='horizontal'
                                                        dataSource={this.state.team.substitute_applications}
                                                        renderItem={(player) => (
                                                            <List.Item
                                                                extra={
                                                                    <>
                                                                        {this.is_in_team() && (
                                                                            <Space>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        this.addPlayerToList(player, 'substitutes', true);
                                                                                    }}
                                                                                    className='btn-success'
                                                                                    type='primary'
                                                                                >
                                                                                    Samþykkja
                                                                                </Button>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        this.removePlayerFromList(player, 'substitute_applications');
                                                                                    }}
                                                                                    type='primary'
                                                                                    danger
                                                                                >
                                                                                    Hafna
                                                                                </Button>
                                                                            </Space>
                                                                        )}
                                                                    </>
                                                                }
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <div style={{position: 'relative'}}>
                                                                            <TeamShirt
                                                                                style={{width: '35px'}}
                                                                                className='shirt'
                                                                                kit_color={this.state.team.away_kit_color}
                                                                                kit_secondary_color={this.state.team.away_kit_secondary_color}
                                                                                kit_template={this.state.team.away_kit_template}
                                                                            ></TeamShirt>
                                                                            <Avatar style={{position: 'absolute', left: 0, bottom: 0}} size='small' src={player.user?.image?.image}>
                                                                                {(player.user?.signature_name || player.unconfirmed_name || '').charAt(0).toUpperCase()}
                                                                            </Avatar>
                                                                        </div>
                                                                    }
                                                                    title={
                                                                        <a className='capitalize' href={'/leikmadur/' + player.uuid + '/'}>
                                                                            {player.user?.signature_name || player.unconfirmed_name}
                                                                        </a>
                                                                    }
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </Card>
                                    )}
                                </Space>
                            </Col>
                        </Row>

                        {this.state.playerModalVisible && (
                            <ModalForm
                                addPlayerToList={this.addPlayerToList.bind(this)}
                                player_type={this.state.modal_player_type}
                                visible={this.state.playerModalVisible}
                                onCancel={this.hidePlayerModal.bind(this)}
                            ></ModalForm>
                        )}

                        <ApplyToJoinList
                            visible={this.state.applyToJoinList}
                            list_name={this.state.applyToJoinList}
                            is_substitute={this.is_in_team('substitutes')}
                            player={this.state.logged_in_player}
                            close={() => this.setState({applyToJoinList: false})}
                            addPlayerToList={this.addPlayerToList.bind(this)}
                        ></ApplyToJoinList>

                        {this.state.delete_event_modal && (
                            <Modal
                                footer={null}
                                visible={this.state.delete_event_modal}
                                title='Alveg viss, viltu eyða?'
                                onCancel={() => {
                                    this.setState({delete_event_modal: false, delete_event: null});
                                }}
                            >
                                <div className='text-center'>
                                    <h3>{this.state.delete_event.title}</h3>
                                    <h4>{this.state.delete_event.location.title}</h4>
                                    {this.state.delete_event.frequency_type === 'YEARLY' && (
                                        <div>
                                            {this.state.delete_event.frequency_count === 1 && <div>Einu sinni á ári</div>}
                                            {this.state.delete_event.frequency_count > 1 && <div>{this.state.delete_event.frequency_count} hvert ár</div>}
                                        </div>
                                    )}

                                    {this.state.delete_event.frequency_type === 'MONTHLY' && (
                                        <div>
                                            {this.state.delete_event.frequency_count === 1 && <div>Mánaðarlega</div>}
                                            {this.state.delete_event.frequency_count > 1 && <div>{this.state.delete_event.frequency_count} hvern mánuð</div>}
                                            <div>{this.state.delete_event.day_of_month}. í hverjum mánuði</div>
                                        </div>
                                    )}

                                    {this.state.delete_event.frequency_type === 'WEEKLY' && (
                                        <div>
                                            {this.state.delete_event.frequency_count === 1 && <span>Vikulega </span>}
                                            {this.state.delete_event.frequency_count > 1 && <span>{this.state.delete_event.frequency_count} hverja viku </span>}
                                            <span>á </span>

                                            <Declensions style={{display: 'inline-block'}} declenasion='thagufall' plural={true}>
                                                {this.state.delete_event.day_of_week}
                                            </Declensions>
                                        </div>
                                    )}

                                    {this.state.delete_event.frequency_type === 'DAILY' && (
                                        <div>
                                            {this.state.delete_event.frequency_count === 1 && <div>Daglega</div>}
                                            {this.state.delete_event.frequency_count > 1 && <div>{this.state.delete_event.frequency_count} hvern dag</div>}
                                        </div>
                                    )}
                                    <h3 style={{margin: 0}}>
                                        {this.state.delete_event.time_of_day &&
                                            this.state.delete_event.time_of_day
                                                .split(':')
                                                .filter((val, key) => key < 2)
                                                .join(':')}
                                    </h3>
                                    <hr />
                                </div>
                                <h2>Þú getur ekki hætt við!</h2>
                                <p>Öllum framtíðarviðburðum verður eytt af dagatalinu þínu (viðburðir úr fortíðinni eyðast ekki).</p>

                                <Space>
                                    <Button
                                        onClick={() => {
                                            axios
                                                .post('/api/delete_event/', {id: this.state.delete_event.id})
                                                .then((rsp) => {
                                                    message.success('Tókst, viðburði hefur verið eytt!');
                                                    this.setState({delete_event_modal: false, delete_event: null, events: this.state.events.filter((e) => e.id !== this.state.delete_event.id)});
                                                })
                                                .catch((err) => {
                                                    if (err.response?.status === 403) {
                                                        message.error(err.response?.data?.message || 'Eitthvað fór úrskeiðis!');
                                                    } else {
                                                        message.error('Úps, eitthvað fór úrskeiðis :(');
                                                    }
                                                });
                                        }}
                                        type='primary'
                                        danger
                                    >
                                        Já eyða
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.setState({delete_event_modal: false, delete_event: null});
                                        }}
                                        type='dashed'
                                    >
                                        Hætta við
                                    </Button>
                                </Space>
                            </Modal>
                        )}

                        {this.state.deletePlayerModalVisible && (
                            <Modal
                                footer={null}
                                visible={this.state.deletePlayerModalVisible}
                                title='Alveg viss?'
                                onCancel={() => {
                                    this.setState({
                                        deletePlayerModalVisible: false,
                                    });
                                }}
                            >
                                <Space>
                                    <Button onClick={this.deletePlayer.bind(this)} size='large' danger type='primary'>
                                        Já
                                    </Button>
                                    <Button
                                        size='large'
                                        type='link'
                                        onClick={() => {
                                            this.setState({
                                                deletePlayerModalVisible: false,
                                            });
                                        }}
                                    >
                                        Hætta við
                                    </Button>
                                </Space>
                            </Modal>
                        )}
                    </Content>
                </Layout>
            </>
        );
    }
}
