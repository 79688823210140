import React from 'react';
import {List} from 'antd';
import {ClockCircleOutlined} from '@ant-design/icons';
import Moment from 'react-moment';
import Avatar from 'antd/lib/avatar/avatar';

export default function SessionListItem(props) {
    const session = props.session;

    return (
        <List.Item
            extra={
                <div>
                    {!session.is_cancelled && (
                        <>
                            {!!session.confirmed_count && (
                                <div style={{color: '#3aca8b'}}>
                                    {session.confirmed_count} staðfest{session.confirmed_count > 1 ? 'ir' : 'ur'}
                                </div>
                            )}

                            {!!session.cant_make_it_count && (
                                <div style={{color: '#ff220c'}}>
                                    {session.cant_make_it_count} {session.cant_make_it_count > 1 ? 'koma' : 'kemur'} ekki
                                </div>
                            )}

                            {!!session.waiting_list_count && <span style={{color: '#bfbfbf'}}>{session.waiting_list_count} á biðlista</span>}
                        </>
                    )}
                </div>
            }
        >
            <List.Item.Meta
                avatar={
                    <a href={'/timi/' + session.uuid + '/'}>
                        <Avatar src={session.event.category.icon} size={30} style={{color: '#e100fe', background: '#fff'}} icon={<ClockCircleOutlined style={{fontSize: 30}} />}></Avatar>
                    </a>
                }
                title={
                    <a className={session.is_cancelled ? 'cancel_linethough' : ''} href={'/timi/' + session.uuid + '/'}>
                        {session.event.team.name}
                    </a>
                }
                description={
                    <div>
                        <Moment style={{color: 'gray'}} locale='is' format='DD MMM, HH:mm'>
                            {session.date}
                        </Moment>
                        {' - '}
                        {session.location ? session.location.title : session.event.location.title}
                    </div>
                }
            />
        </List.Item>
    );
}
