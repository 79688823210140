import Axios from 'axios';

export const get_or_create_location = (place) => {
    return new Promise((resolve, reject) => {
        Axios.get(`/api/get_location/${place.placeId}`)
            .then((rsp) => {
                resolve(rsp.data);
            })
            .catch((err) => {
                const new_place = {
                    title: place.formattedSuggestion?.mainText,
                    description: place.description,
                    google_place_id: place.placeId,
                    google_place_main_text: place.formattedSuggestion?.mainText,
                    google_place_secondary_text: place.formattedSuggestion?.secondaryText,
                };

                return Axios.post('/api/location/create/', new_place).then((rsp) => {
                    resolve(rsp.data);
                });
            });
    });
};
