import React, {Component} from 'react';
import {Layout, Card, Col, Row, Button, List, Space} from 'antd';

// Components
import TeamShirt from '../../components/TeamShirt/TeamShirt';
import HeroSection from '../../components/HeroSection/HeroSection';
import EventCalendar from '../../components/EventCalendar/EventCalendar';

import SessionListItem from '../../components/SessionListItem/SessionListItem';

// Libraries
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';
import SVG from 'react-inlinesvg';

// Less
import './Frontpage.less';

//REDUX
import {connect} from 'react-redux';
import {getUser} from '../../actions/user';
import Avatar from 'antd/lib/avatar/avatar';

const {Content} = Layout;

export class Frontpage extends Component {
    state = {
        loading: true,
        teams: [],
    };

    componentDidMount() {
        const start_date = moment().startOf('month').startOf('isoWeek');
        const end_date = moment().endOf('month').endOf('isoWeek');

        axios.get('/api/team/?page_size=5&ordering=-created').then((rsp) => {
            this.setState({teams: rsp.data.results});
        });

        if (window.me) {
            this.get_user_data(start_date, end_date);
        }
        this.get_anonymous_data();
    }

    get_anonymous_data() {
        axios.get('/api/calendar_session/?page_size=100&date__gte=' + moment().startOf('day').toISOString() + '&date__lte=' + moment().endOf('day').toISOString()).then((rsp) => {
            this.setState({
                display_sessions: rsp.data.results,
            });
        });
    }

    get_user_data(start_date, end_date) {
        const nowish = moment().subtract(30, 'minutes').toISOString();
        const sessions = axios.get('/get_logged_in_future_sessions/?page_size=5&date__gte=' + nowish);
        const teams = axios.get('/get_logged_in_teams/');

        Promise.all([teams, sessions]).then((rsp) => {
            const logged_in_player_teams = rsp[0].data?.length ? rsp[0].data : null;
            const logged_in_player_sessions = rsp[1].data?.results?.length ? rsp[1].data.results : null;

            /*  let next_session = [];
            if (logged_in_player_sessions && logged_in_player_sessions.length) {
                next_session = [logged_in_player_sessions.shift()];
            } */
            this.setState({
                logged_in_player_teams,
                logged_in_player_sessions,
                /*    next_session, */
                user_data_loading: false,
            });

            this.get_anonymous_data();
        });
    }

    render() {
        const start_date = moment().startOf('month').startOf('isoWeek');
        const end_date = moment().endOf('month').endOf('isoWeek');
        const calendar_url = '/api/calendar_session/?page_size=120&date__gte=' + start_date.toISOString() + '&date__lte=' + end_date.toISOString();

        return (
            <Content>
                <HeroSection id='frontpage_hero_section' vheight='100' heightSubtract='64px'>
                    <>
                        <div id='bottom_divider'>
                            <div id='bottom_divider_inner'></div>
                        </div>
                        <div className='text-center'>
                            <div className='logo_container'>
                                <SVG
                                    id='front_page_logo'
                                    style={{width: '100%'}}
                                    baseURL='/'
                                    cacheRequests={false}
                                    description='Logo'
                                    preProcessor={(code) => {
                                        code = code.replace(/fill:.?#181818/g, 'fill:#b001ff');
                                        return code;
                                    }}
                                    src={'/static/images/logo/logo_gray.svg'}
                                    title='Logo'
                                    key={'logo'}
                                    uniqueHash={'logo'}
                                    uniquifyIDs={true}
                                />
                            </div>
                            <h1 className='mb0'>Vantar í bolta?</h1>
                            <h3>Haldið utan um mætingu / Finnið leikmenn</h3>
                            {!window.me && (
                                <>
                                    <br></br>
                                    <div className='text-center'>
                                        <Space>
                                            <Button className='btn-success' size='large' type='primary'>
                                                <a style={{color: '#fff'}} href='/nyskra/'>
                                                    Stofna aðgang
                                                </a>
                                            </Button>

                                            <Button size='large' type='dashed'>
                                                <a href='/innskra/'>Innskrá</a>
                                            </Button>
                                        </Space>
                                    </div>
                                </>
                            )}

                            {window.me ? (
                                <>
                                    <Row type='flex' style={{justifyContent: 'center', textAlign: 'left'}} gutter={[10, 10]}>
                                        <Col xs={24} sm={8}>
                                            <Space direction='vertical' style={{display: 'flex'}}>
                                                {/*   <Card className='frontpage_card' loading={this.state.user_data_loading} style={{flex: 1}} title={'Næsti viðburður'}>
                                                        <List
                                                            itemLayout='horizontal'
                                                            dataSource={this.state.next_session || []}
                                                            locale={{emptyText: 'Engir viðburðir fundust'}}
                                                            renderItem={(session) => (
                                                                <>
                                                                    <List.Item
                                                                        extra={
                                                                            <Moment style={{color: 'gray'}} locale='is' format='DD MMM - HH:mm'>
                                                                                {session.date}
                                                                            </Moment>
                                                                        }
                                                                    >
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <Avatar
                                                                                    src={session.event.category.icon}
                                                                                    size={30}
                                                                                    style={{color: '#e100fe', background: '#fff'}}
                                                                                    icon={<ClockCircleOutlined style={{fontSize: 30}} />}
                                                                                ></Avatar>
                                                                            }
                                                                            title={<a href={'/timi/' + session.uuid + '/'}>{session.event.team.name}</a>}
                                                                            description={<div>{session.location ? session.location.title : session.event.location.title}</div>}
                                                                        />
                                                                    </List.Item>
                                                                </>
                                                            )}
                                                        />
                                                    </Card> */}

                                                <Card
                                                    className='frontpage_card'
                                                    title='Liðin mín'
                                                    extra={
                                                        <Button className='btn-success' type='primary'>
                                                            <a style={{color: '#fff'}} href='/nyttlid/'>
                                                                + Stofna lið
                                                            </a>
                                                        </Button>
                                                    }
                                                >
                                                    <List
                                                        itemLayout='horizontal'
                                                        dataSource={this.state.logged_in_player_teams || []}
                                                        locale={{emptyText: 'Engin lið fundust'}}
                                                        renderItem={(team) => (
                                                            <List.Item>
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <div style={{position: 'relative'}}>
                                                                            <a href={'/lid/' + team.uuid + '/'}>
                                                                                <TeamShirt
                                                                                    style={{width: '35px'}}
                                                                                    className='shirt'
                                                                                    kit_color={team.main_kit_color}
                                                                                    kit_secondary_color={team.main_kit_secondary_color}
                                                                                    kit_template={team.main_kit_template}
                                                                                ></TeamShirt>
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    title={<a href={'/lid/' + team.uuid + '/'}>{team.name}</a>}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Card>
                                            </Space>
                                        </Col>

                                        <Col xs={24} sm={8}>
                                            <Card className='frontpage_card' loading={this.state.user_data_loading} style={{flex: 1}} title={'Næstu viðburðir'}>
                                                <List
                                                    itemLayout='horizontal'
                                                    dataSource={this.state.logged_in_player_sessions || []}
                                                    locale={{emptyText: 'Engir viðburðir fundust'}}
                                                    renderItem={(session) => {
                                                        return <SessionListItem session={session}></SessionListItem>;
                                                    }}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <br />
                                    <div className='team_example'>
                                        <img style={{width: '100%'}} src='/static/images/team_example.jpg' alt='' />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                </HeroSection>

                <HeroSection id='notifications_section' vheight={35} backgroundColor='#fff'>
                    <div className='text-center'>
                        <h1>Sjálfkrafa áminningar</h1>
                        <h3>SMS og FB Messenger</h3>

                        <div className='notification_preview'></div>
                        <div className='notification_preview_text'>
                            <div className='phone_info_top'>
                                <div>Text Message</div>
                                <div>Today 12:00</div>
                            </div>
                            <div className='inner_text'>Mánudagsbumba - Mæting í dag klukkan 19:50. Meldaðu þig hér: https://vantaribolta.is/aminning/1</div>
                        </div>
                    </div>
                </HeroSection>

                <HeroSection id='event_calendar' vheight='100' heightSubtract='64px'>
                    <Row type='flex' style={{justifyContent: 'center', gap: 10}}>
                        <Col style={{display: 'flex'}} xs={24} sm={14}>
                            {
                                <Card className='frontpage_card frontpage_calendar_card' style={{flex: 1}} title='Allir viðburðir á vantaribolta.is'>
                                    <EventCalendar
                                        className='frontpage_calendar'
                                        url={calendar_url}
                                        onSelect={(sessions, moment_date) => {
                                            this.setState({
                                                selected_date: moment(moment_date),
                                            });

                                            axios.get('/api/calendar_session/?page_size=100&date__gte=' + moment_date.startOf('day').toISOString() + '&date__lte=' + moment_date.endOf('day').toISOString()).then((rsp) => {
                                                this.setState({
                                                    display_sessions: rsp.data.results,
                                                });
                                            });

                                            if (window.innerWidth < 768) {
                                                document.getElementById('display_sessions').scrollIntoView({behavior: 'smooth'});
                                            }
                                        }}
                                        dateCellRender={(sessions, moment_date) => {
                                            const locations = {};

                                            const sessions_for_day = sessions?.filter((session) => moment_date.isSame(moment(session.date), 'day'));

                                            const icons = [];

                                            sessions_for_day.forEach((session) => {
                                                const location = session.location || session.event.location;
                                                locations['_' + location.id] = locations['_' + location.id] || JSON.parse(JSON.stringify(location));
                                                locations['_' + location.id].count = locations['_' + location.id].count ? locations['_' + location.id] + 1 : 1;
                                                locations['_' + location.id].icons = locations['_' + location.id].icons || [];
                                                const icon = session.event.category.icon;
                                                if (icons.indexOf(icon) === -1) icons.push(icon);
                                            });

                                            const _locations = Object.keys(locations)
                                                .map((id) => {
                                                    return locations[id];
                                                })
                                                .sort((location, before) => {
                                                    return location.count >= before;
                                                });

                                            return (
                                                <>
                                                    <Space>
                                                        {icons.map((icon, key) => {
                                                            return <Avatar key={key} size='small' src={icon}></Avatar>;
                                                        })}
                                                    </Space>
                                                    {_locations.map((sess, key) => {
                                                        return (
                                                            <div key={'_' + key} style={{textTransform: 'capitalize'}}>
                                                                <span>
                                                                    {sess.title}
                                                                    {key === _locations.length - 1 ? '' : ', '}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            );
                                        }}
                                    ></EventCalendar>
                                </Card>
                            }
                        </Col>

                        <Col id='display_sessions' style={{display: 'flex'}} xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                            <Card className='frontpage_card' loading={!!!this.state.display_sessions} style={{flex: 1}} title={this.state?.selected_date ? this.state.selected_date.format('LL') : moment().format('LL')}>
                                <List
                                    itemLayout='horizontal'
                                    dataSource={!!this.state.display_sessions ? this.state.display_sessions : []}
                                    locale={{emptyText: 'Engir viðburðir fundust fyrir dagsetinguna sem er valin'}}
                                    renderItem={(session) => <SessionListItem session={session}></SessionListItem>}
                                />
                            </Card>
                        </Col>
                    </Row>
                </HeroSection>

                <HeroSection vheight={50} backgroundColor='#fff'>
                    <div className='text-center'>
                        <h1>Nýjustu liðin</h1>
                        {this.state.teams.map((team) => {
                            return (
                                <div key={team.id} className='featured_shirt'>
                                    <div className='shirts_container'>
                                        <TeamShirt kit_color={team.main_kit_color} kit_secondary_color={team.main_kit_secondary_color} kit_template={team.main_kit_template}></TeamShirt>
                                        <div className='featured_away_kit'>
                                            <TeamShirt kit_color={team.away_kit_color} kit_secondary_color={team.away_kit_secondary_color} kit_template={team.away_kit_template}></TeamShirt>
                                        </div>
                                    </div>
                                    <h3 className='featured_team_name'>{team.name}</h3>
                                    <div className='featured_team_info'>
                                        <div>
                                            <strong>{team.players.length} </strong>
                                            leik{team.players.length === 1 ? 'maður' : 'menn'}
                                        </div>
                                        <div>
                                            <strong> {team.substitutes.length} </strong> vara{team.substitutes.length === 1 ? 'maður' : 'menn'}
                                        </div>
                                        <br />
                                    </div>
                                    <Button type='dashed' block>
                                        <a href={'/lid/' + team.uuid + '/'}>Skoða</a>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </HeroSection>
                {/* <Content>
                        <Row>
                            <Col>
                                <section style={{background: 'white'}}>
                                    <h1>Hello world</h1>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </p>
                                </section>
                            </Col>
                        </Row>
                    </Content> */}
            </Content>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, {getUser})(Frontpage);
