import React, { Component } from 'react';
import { Form, Input, Card, Layout, Row, Col, Space, message, Button, Select } from 'antd';

import Axios from 'axios';

const { Content } = Layout;

export default class CreateEvent extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        logged_in_player: null,
        player: {},
    };

    componentDidMount() {
        Axios.get('/api/get_or_create_player/?user=' + window.me).then((rsp) => {
            this.setState({
                player: rsp.data.id,
            });
        });
    }

    onFinish = (values) => {
        this.setState({
            loading: true,
        });

        values.captains = [this.state.player];
        values.players = [this.state.player];

        Axios.post('/api/create/team/', values)
            .then((rsp) => {
                message.success('Liðið hefur verið stofnað, vúhúúú');
                setTimeout(() => {
                    window.top.location.href = '/lid/' + rsp.data.uuid + '/';
                }, 500);
            })
            .catch((err) => {
                message.error('Úps, eitthvað fór úrskeiðis :(');
            });
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    render() {
        return (
            <>
                <Layout>
                    <Content>
                        <Row gutter={[10, 10]} style={{ margin: 10 }}>
                            <Col xs={24} sm={18} md={16} lg={12} xl={10} xxl={8}>
                                <Form ref={this.formRef} layout='vertical' name='basic' initialValues={{ remember: true }} onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
                                    <Space direction='vertical'>
                                        <Card loading={this.state.loading} title='Stofna nýtt lið' type='inner'>
                                            <Form.Item label='Nafn á liði' name='name' rules={[{ required: true, message: 'Þú verður að gefa liðinu nafn.' }]}>
                                                <Input size='large' autoComplete='new-password' />
                                            </Form.Item>

                                            <Form.Item
                                                label='Hvenær myndast "gul viðvörun" fyrir viðburði?'
                                                initialValue={2}
                                                name='in_need_buffer'
                                                extra='Þegar "gul viðvörun" hefur myndast hafa venjulegir leikmenn ekki lengur forgang yfir leikmenn á varamannabekknum. Laus sæti opnast fyrir varamannabekkinn (fyrstur kemur fyrstur fær).'
                                            >
                                                <Select style={{ width: '100%' }}>
                                                    <Select.Option value={0.5}>30 mín fyrir viðburð</Select.Option>
                                                    <Select.Option value={1}>1 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={1.5}>1.5 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={2}>2 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={2.5}>2.5 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={3}>3 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={3.5}>3.5 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={4}>4 klst fyrir viðburð</Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item label='Hvenær myndast "rauð viðvörun" fyrir viðburði?' initialValue={1} name='emergency_buffer'
                                                extra='Þegar "rauð viðvörun" hefur myndast er hægt að bæta ókunnugum við viðburðinn (aðeins leikmenn geta gert það). Athugið að ókunnugir geta samt alltaf bætt sér á biðlistann.'>
                                                <Select style={{ width: '100%' }}>
                                                    <Select.Option value={0.5}>30 mín fyrir viðburð</Select.Option>
                                                    <Select.Option value={1}>1 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={1.5}>1.5 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={2}>2 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={2.5}>2.5 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={3}>3 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={3.5}>3.5 klst fyrir viðburð</Select.Option>
                                                    <Select.Option value={4}>4 klst fyrir viðburð</Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                name='allow_outsiders'
                                                initialValue={true}
                                                label='Leyfa ókunnugum að skrá sig á biðlista fyrir viðburði?'
                                                extra='Leikmenn geta bætt fólki af biðlistanum við viðburðinn (þegar "rauð viðvörun" hefur myndast).'
                                            >
                                                <Select style={{ width: '100%' }}>
                                                    <Select.Option value={true}>Já, leyfa öllum að skrá sig á biðlista</Select.Option>
                                                    <Select.Option value={false}>Nei, aðeins fyrir okkar hóp og varamannabekkinn</Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item>
                                                <Button size='large' type='primary' loading={this.state.loading} block htmlType='submit'>
                                                    Vista
                                                </Button>
                                            </Form.Item>
                                        </Card>
                                    </Space>
                                </Form>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </>
        );
    }
}
